import * as React from "react";
import * as turf from "@turf/turf";
import './index.css';
import { Redirect } from "react-router";
import moment from "moment";
import { RouteAndMarkerMap, defaultRouteAndMarkerMapProps } from "../../shared/RouteAndMarkerMap";
import { ImpactMapType, MapType, MaskType } from "src/types/MapType";
import { AlertData, BasicLatLng, Blurb, BlurbsByIndex, CycloneData, EarthquakeData, EventData, FireData, ImpactTag, ImpactTileset, ImpactTilesetsByIndex, LatLngBounds, LightningData, LoadableResult, LocationData, PowerOutageData, RatingsDataWithLocationInfo, RoadClosureData, RoadStatusData, RoadWorkData, SelectedCityState, SpecialEventData, StormData, StormReportData, TrafficCongestionData, TrafficIncidentData, TruckWarningData, UserState, VehicleTrackingData, ViewportData, VolcanoData, WeatherConditions, WeatherData, WeatherStationData, WeatherTilesetsByIndex, WildfireData, getEventDescription, getPointForEvent, haversineDistance, isLatLngInViewport } from "src/types";
import { ALL_RATING_KEYS, RatingKey, formatRatingKey, isImpactKey, isWeatherKey } from "src/types/RatingKey";
import { TimeSeriesGraphView } from "../Impact/TimeSeriesGraphView";
import { Button, Chip, CircularProgress, FormControlLabel, IconButton, InputAdornment, MenuItem, Paper, Radio, RadioGroup, Select, Switch, Tab, Tabs, TextField, Tooltip, Typography } from "@mui/material";
import { ImpactMapSection, getClientImpactSections, getClientRadarSection } from "../Impact/sections";
import { CancelRounded, ClearOutlined, Close, KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import SearchIcon from '@mui/icons-material/Search';
import { API_HOST, cardBackgroundColor } from "src/constants";
import { ImpactLevel, RoadRiskPolylineSegment, RouteData, SlowdownPolylineSegment, darkTagColorForImpactLevel, findImpactLevel, getBoundsForRoute, wordForImpactLevel } from "src/types/routes";
import { TilesetUtils } from "../Impact";
import { LegendComponent } from "../Impact/LegendComponent";
import { MapControlsComponent } from "src/components/shared/MapControlsComponent";
import AlertTimelineView from "./AlertTimelineView";
import NowcastMenu from "./NowcastMenu";
import { exportCSVFile, snakeCaseToTitleCase } from "src/util";
import { RoadStatusLegendComponent } from "../Dashboard/RoadStatusLegendComponent";
import { AlertsLegend } from "../Alerts/AlertsLegend";
import { Bounds } from "google-map-react";
import { AssetFilter, doesLocationPassFilterSubhourly, doesRoutePassFilterSubhourly, doesVehiclePassFilterSubhourly } from "src/types/AssetFilter";
import { isEqual } from "lodash";
import { StormReportsLegendComponent } from "../Dashboard/StormReportLegendComponent";
import { EventCalloutView } from "./EventCalloutView";
import { ExportIcon } from "../Dashboard/ImpactDetailView";
import { calculateLightningOpacityFromAge } from "../Impact/MapManagerV2";
import VehicleTimelineView, { VEHICLE_CHART_VIEWS } from "./VehicleTimelineView";
import { PolylineType } from "../Dashboard";
import { ImpactedRoutesTable } from "../Dashboard/ImpactedRoutesTable";
import { filterRoutes, findMatchingRouteForVehicle, findMatchingVehicleForRoute, Timeframe } from "../Dashboard/data";
import PortalTour from "../PortalTour";
import { Config } from "src/components/shared/useConfig";
import { useLocalStorage, useLocalStorageBoolean } from "src/components/shared/useLocalStorage";
import { requestWrapper } from "src/actions/Ratings";
import { unmarshalRoute } from "src/types/unmarshal";

// function darkenToContrast(hexColor: string) {
//     const white = [255, 255, 255];
//     const contrastRatio = (l1: number, l2: number) => (l1 + 0.05) / (l2 + 0.05);

//     // Convert hex to RGB
//     const hexToRgb = (hex: string) => {
//         let bigint = parseInt(hex.slice(1), 16);
//         return [(bigint >> 16) & 255, (bigint >> 8) & 255, bigint & 255];
//     };

//     // Convert RGB to luminance
//     const rgbToLuminance = (rgb: number[]) => {
//         return rgb.map(c => {
//             c /= 255;
//             return c <= 0.03928 ? c / 12.92 : Math.pow((c + 0.055) / 1.055, 2.4);
//         }).reduce((acc, val, i) => acc + val * [0.2126, 0.7152, 0.0722][i], 0);
//     };

//     // Darken RGB
//     const darken = (rgb: number[], factor: number) => rgb.map(c => Math.max(0, c - factor));

//     // Start process
//     let rgb = hexToRgb(hexColor);
//     let luminance = rgbToLuminance(rgb);
//     let whiteLuminance = rgbToLuminance(white);
//     let factor = 0;

//     while (contrastRatio(whiteLuminance, luminance) < 2 && factor < 255) {
//         factor += 1;
//         rgb = darken(rgb, 1);
//         luminance = rgbToLuminance(rgb);
//     }

//     // Convert back to hex
//     const rgbToHex = (rgb: number[]) =>
//         `#${rgb.map(c => c.toString(16).padStart(2, '0')).join('')}`;

//     return rgbToHex(rgb);
// }

export interface Props {
    userData: UserState;

    selectedCityState: SelectedCityState;
    selectedCity?: LocationData;
    selectedVehicle?: VehicleTrackingData;
    selectedRoute?: RouteData;
    selectedMapCategorySlug: string;
    selectedGraphView: string;
    selectedMapType: ImpactMapType;

    impactTilesets?: ImpactTilesetsByIndex;
    subhourlyImpactTilesets?: ImpactTilesetsByIndex;
    weatherTilesets?: WeatherTilesetsByIndex;
    subhourlyWeatherTilesets?: WeatherTilesetsByIndex;
    speedIncrements: number[];
    selectedSpeedMultiplierIndex: number;
    baseAnimationDelay: number;
    tileOpacity: number | undefined;

    subhourlyRatingsData?: RatingsDataWithLocationInfo;
    ratingsData?: RatingsDataWithLocationInfo;
    weatherData?: WeatherData;
    blurbs: BlurbsByIndex;
    isCalloutViewVisible?: boolean;

    governmentalAlerts: LoadableResult<AlertData[]>;

    cyclones?: CycloneData[];
    earthquakes?: EarthquakeData[];
    lightning?: LightningData[];
    powerOutages?: PowerOutageData[];
    storms?: StormData[];
    stormReports?: StormReportData[];
    volcanoes?: VolcanoData[];
    fires?: FireData[];
    wildfires?: WildfireData[];

    roadStatus?: RoadStatusData[];
    roadWork?: RoadWorkData[];
    roadClosures?: RoadClosureData[];
    specialEvents?: SpecialEventData[];
    trafficCongestion?: TrafficCongestionData[];
    trafficIncidents?: TrafficIncidentData[];
    truckWarnings?: TruckWarningData[];
    weatherStations?: WeatherStationData[];

    isCyclonesVisible: boolean;
    isEarthquakesVisible: boolean;
    isLightningVisible: boolean;
    isPowerOutagesVisible: boolean;
    isStormsVisible: boolean;
    isStormReportsVisible: boolean;
    isVolcanoesVisible: boolean;
    isFiresVisible: boolean;
    isWildfiresVisible: boolean;

    isRoadStatusVisible: boolean;
    isRoadWorkVisible: boolean;
    isRoadClosuresVisible: boolean;
    isSpecialEventsVisible: boolean;
    isTrafficCongestionVisible: boolean;
    isTrafficIncidentsVisible: boolean;
    isTruckWarningsVisible: boolean;
    isWeatherStationsVisible: boolean;

    selectedCyclone?: CycloneData;
    selectedEarthquake?: EarthquakeData;
    selectedLightning?: LightningData;
    selectedPowerOutage?: PowerOutageData;
    selectedStorm?: StormData;
    selectedStormReport?: StormReportData;
    selectedVolcano?: VolcanoData;
    selectedFire?: FireData;
    selectedWildfire?: WildfireData;

    selectedRoadStatus?: RoadStatusData;
    selectedRoadWork?: RoadWorkData;
    selectedRoadClosure?: RoadClosureData;
    selectedSpecialEvent?: SpecialEventData;
    selectedTrafficCongestion?: TrafficCongestionData;
    selectedTrafficIncident?: TrafficIncidentData;
    selectedTruckWarning?: TruckWarningData;
    selectedWeatherStation?: WeatherStationData;

    showDisruptionIndex: boolean;
    showWildfireIndices: boolean;

    onCitySelected: (city: LocationData | undefined) => void;

    onVehicleSelected: (vehicle: VehicleTrackingData | undefined) => void;
    onVehicleMessageRequested: (vehicle: VehicleTrackingData, message: string, urgent: boolean) => void;

    setSelectedRoute: (route: RouteData | undefined) => void;
    onRouteRefreshRequested: (route: RouteData) => void;
    onRouteRefreshErrorDismissed: () => void;

    onSetCurrentTileset: (tileset: ImpactTileset) => void;
    onAnimationSpeedChanged: (selectedSpeedMultiplierIndex: number, animationDelay: number) => void;
    onSetTileOpacity: (tileOpacity: number) => void;

    onLoadEvents: () => void;
    onLoadRoadStatus: () => void;
    onReloadSelectedCity: (selectedCity: LocationData, takeb: string) => void;
    onReloadSelectedCityData: () => void;
    onCyclonesToggled: (toggled: boolean) => void;
    onEarthquakesToggled: (toggled: boolean) => void;
    onLightningToggled: (toggled: boolean) => void;
    onPowerOutagesToggled: (toggled: boolean) => void;
    onStormsToggled: (toggled: boolean) => void;
    onStormReportsToggled: (toggled: boolean) => void;
    onVolcanoesToggled: (toggled: boolean) => void;
    onFiresToggled: (toggled: boolean) => void;
    onWildfiresToggled: (toggled: boolean) => void;

    onCycloneSelected: (cyclone: CycloneData | undefined) => void;
    onEarthquakeSelected: (earthquake: EarthquakeData | undefined) => void;
    onLightningSelected: (lightning: LightningData | undefined) => void;
    onPowerOutageSelected: (powerOutage: PowerOutageData | undefined) => void;
    onStormSelected: (storm: StormData | undefined) => void;
    onStormReportSelected: (stormReport: StormReportData | undefined) => void;
    onVolcanoSelected: (volcano: VolcanoData | undefined) => void;
    onFireSelected: (fire: FireData | undefined) => void;
    onWildfireSelected: (wildfire: WildfireData | undefined) => void;
    onClearSelectedEvent: () => void;

    onRoadStatusSelected: (roadStatus: RoadStatusData | undefined) => void;
    onRoadWorkSelected: (roadWork?: RoadWorkData) => void;
    onRoadClosureSelected: (roadClosure?: RoadClosureData) => void;
    onSpecialEventSelected: (specialEvent?: SpecialEventData) => void;
    onTrafficCongestionSelected: (trafficCongestion?: TrafficCongestionData) => void;
    onTrafficIncidentSelected: (trafficIncident?: TrafficIncidentData) => void;
    onTruckWarningSelected: (truckWarning?: TruckWarningData) => void;
    onWeatherStationSelected: (weatherStation?: WeatherStationData) => void;
    onClearSelectedRoadCondition: () => void;
    onTrafficLayerSelected: (trafficLayer: string | undefined) => void;

    onMapSelected: (selectedMapCategory: ImpactMapSection, selectedMapType: MapType) => void;
    openedPage: (page: string) => void;

    loadLocation: (locationId: string) => void;
    loadGovernmentalAlertsIfNeeded: () => void;
}

/**
 *  Nowcast Asset Types.
 * Currently map directly to city.userMetadata.category
 * Eventually should include routes, vehicles, etc.
 */
export interface AssetType {
    label: string;
    /**
     * used as a unique id for determing if two asset types are equivalent
     * undefined = 'other' i.e. not marked with a category
     */
    category: string | undefined;
}

export const otherAssetType: AssetType = { label: 'Other', category: undefined };

export type AssetGroupType = 'location' | 'vehicle' | 'route';

export function cityMatchesAssetType(city: LocationData, assetType: AssetType) {
    if (assetType.category === undefined) {
        return !city.userMetadata || !city.userMetadata.category;
    } else {
        return city.userMetadata && city.userMetadata.category === assetType.category;
    }
}

export function cityMatchesAssetTypes(city: LocationData, assetTypes: AssetType[]) {
    return assetTypes.some(assetType => cityMatchesAssetType(city, assetType));
}

export function vehicleMatchesAssetType(vehicle: VehicleTrackingData, assetType: AssetType) {
    if (assetType.category === undefined) {
        return vehicle.externalGroupIds.length === 0;
    } else {
        return vehicle.externalGroupIds.includes(assetType.category);
    }
}

export function vehicleMatchesAssetTypes(vehicle: VehicleTrackingData, assetTypes: AssetType[]) {
    return assetTypes.some(assetType => vehicleMatchesAssetType(vehicle, assetType));
}

export function routeMatchesAssetType(route: RouteData, assetType: AssetType) {
    // place holder might add route asset types in the future
    return true;
}

export function routeMatchesAssetTypes(route: RouteData, assetTypes: AssetType[]) {
    return assetTypes.some(assetType => routeMatchesAssetType(route, assetType));
}

export interface NearbyLightning {
    within10Miles: number;
    within20Miles: number;
    within30Miles: number;

    closestStrike?: LightningData;
    closestDistance?: number;
}

export const getAssetName = (asset: LocationData | VehicleTrackingData | RouteData): string => {
    if ((asset as any).assetType === 'route') {
        const route = asset as RouteData;
        return `${route.origin.label} → ${route.destination.label}`;
    } else if ((asset as any).assetType === 'vehicle') {
        const vehicle = asset as VehicleTrackingData;
        return (vehicle.name ?? "").length > 0 ? vehicle.name! : vehicle.externalId;
    } else {
        const location = asset as LocationData;
        return location.name;
    }
};

export const getAssetImpactLevel = (asset: LocationData | VehicleTrackingData | RouteData, timeframeIndex: number): ImpactLevel => {
    if ((asset as any).assetType === 'route') {
        const route = asset as RouteData;
        return route.latestRouteResults !== undefined ? findImpactLevel(route.latestRouteResults[0]) : ImpactLevel.Unknown;
    } else if ((asset as any).assetType === 'vehicle') {
        const vehicle = asset as VehicleTrackingData;
        return vehicle.currentImpact?.overallImpactLevel ?? ImpactLevel.Unknown;
    } else {
        const location = asset as LocationData;
        if (timeframeIndex === 0) {
            return location.subhourlyImpactSummary?.impactLevel ?? ImpactLevel.Unknown;
        } else {
            return location.extendedImpactSummary?.impactLevel ?? ImpactLevel.Unknown;
        }
    }
};

const getAssetImpactTags = (asset: LocationData | VehicleTrackingData | RouteData, timeframeIndex: number): ImpactTag[] => {
    const impactTags: ImpactTag[] = [];
    if ((asset as any).assetType === 'route') {
        // TODO need to determine how to ge impact tags for route
        const route = asset as RouteData;
        if (route.latestRouteResults) {
            const impactLevel = findImpactLevel(route.latestRouteResults[0]);
            route.latestRouteResults[0].weatherFlags.map(weather => {
                impactTags.push({ id: `${route.id}_${weather}`, source: 'HYPERR', text: weather, value: undefined, impactLevel: impactLevel });
            });
        }
    } else if ((asset as any).assetType === 'vehicle') {
        const vehicle = asset as VehicleTrackingData;
        vehicle.currentImpact?.tags.map(tag => {
            // create separate tags for tags that have been combined with same impact level
            if (tag.text.includes(',')) {
                tag.text.split(',').map((tagText: string) => impactTags.push({ id: tag.id, source: tag.source, text: tagText.trim(), value: tag.value, impactLevel: tag.impactLevel }));
            } else {
                impactTags.push(tag);
            }
        });
    } else {
        const location = asset as LocationData;
        if (timeframeIndex === 0) {
            location.subhourlyImpactSummary?.tags.map(tag => {
                // create separate tags for tags that have been combined with same impact level
                if (tag.text.includes(',')) {
                    tag.text.split(',').map((tagText: string) => impactTags.push({ id: tag.id, source: tag.source, text: tagText.trim(), value: tag.value, impactLevel: tag.impactLevel }));
                } else {
                    impactTags.push(tag);
                }
            });
        } else {
            location.extendedImpactSummary?.tags.map(tag => {
                // create separate tags for tags that have been combined with same impact level
                if (tag.text.includes(',')) {
                    tag.text.split(',').map((tagText: string) => impactTags.push({ id: tag.id, source: tag.source, text: tagText.trim(), value: tag.value, impactLevel: tag.impactLevel }));
                } else {
                    impactTags.push(tag);
                }
            });
        }
    }

    return impactTags;
};

const getAssetAlerts = (asset: LocationData | VehicleTrackingData | RouteData, governmentalAlerts: AlertData[], timeframeIndex: number): AlertData[] => {
    const assetAlerts: AlertData[] = [];
    if ((asset as any).assetType === 'route') {
        // TODO
        //const route = asset as RouteData;
    } else if ((asset as any).assetType === 'vehicle') {
        const vehicle = asset as VehicleTrackingData;
        vehicle.currentImpact?.tags.forEach(tag => {
            if (tag.source === 'governmental_alert' && governmentalAlerts) {
                const existingGovAlert = governmentalAlerts.find(alert => alert.id === tag.id);
                if (existingGovAlert) assetAlerts.push(existingGovAlert);
            }
        });
    } else {
        const location = asset as LocationData;
        if (timeframeIndex === 0) {
            location.subhourlyImpactSummary?.tags.forEach(tag => {
                if (tag.source === 'governmental_alert' && governmentalAlerts) {
                    const existingGovAlert = governmentalAlerts.find(alert => alert.id === tag.id);
                    if (existingGovAlert) assetAlerts.push(existingGovAlert);
                }
            });
        } else {
            location.extendedImpactSummary?.tags.forEach(tag => {
                if (tag.source === 'governmental_alert' && governmentalAlerts) {
                    const existingGovAlert = governmentalAlerts.find(alert => alert.id === tag.id);
                    if (existingGovAlert) assetAlerts.push(existingGovAlert);
                }
            });
        }
    }

    return assetAlerts;
};

const getFilteredLocations = (
    locations: LocationData[],
    locationSelectedAssetTypes: AssetType[],
    filters: AssetFilter[],
): LocationData[] => {
    return locations.filter(city => cityMatchesAssetTypes(city, locationSelectedAssetTypes))
        .filter(city => {
            return filters.every(filter => doesLocationPassFilterSubhourly(city, filter));
        });
};

const getFilteredVehicles = (
    vehicles: VehicleTrackingData[],
    vehicleSelectedAssetTypes: AssetType[],
    filters: AssetFilter[],
): VehicleTrackingData[] => {
    return vehicles.filter(vehicle => vehicleMatchesAssetTypes(vehicle, vehicleSelectedAssetTypes))
        .filter(vehicle => {
            return filters.every(filter => doesVehiclePassFilterSubhourly(vehicle, filter));
        });
};

const getFilteredRoutes = (
    routes: RouteData[],
    timeframe: Timeframe,
    routeSelectedAssetTypes: AssetType[],
    filters: AssetFilter[],
): RouteData[] => {
    return filterRoutes(routes, timeframe)
        .filter(route => routeMatchesAssetTypes(route, routeSelectedAssetTypes))
        .filter(route => {
            return filters.every(filter => doesRoutePassFilterSubhourly(route, filter));
        });
};

export const ClientNowcastPage = (props: Props) => {
    // by checking if the user is present in this wrapper component
    // we can have a non null userData in the inner portion
    if (props.userData === undefined || props.userData?.token === undefined) {
        return <Redirect to={"/logout"} />;
    }
    return <ClientNowcastPageInner {...props} userData={props.userData!} />;
};

const ClientNowcastPageInner = ({
    userData, selectedCity, selectedVehicle, selectedRoute, weatherData, subhourlyRatingsData, ratingsData, blurbs, impactTilesets, subhourlyImpactTilesets, weatherTilesets, subhourlyWeatherTilesets, tileOpacity, governmentalAlerts,
    cyclones, earthquakes, lightning, powerOutages, storms, stormReports, volcanoes, fires, wildfires, selectedMapType, selectedMapCategorySlug,
    selectedCyclone, selectedEarthquake, selectedLightning, selectedPowerOutage, selectedStorm, selectedStormReport, selectedVolcano, selectedFire, selectedWildfire,
    roadStatus, roadWork, roadClosures, specialEvents, trafficCongestion, trafficIncidents, truckWarnings, weatherStations,
    selectedRoadStatus, selectedRoadWork, selectedRoadClosure, selectedSpecialEvent, selectedTrafficCongestion, selectedTrafficIncident, selectedTruckWarning, selectedWeatherStation,
    isRoadStatusVisible, isRoadWorkVisible, isRoadClosuresVisible, isSpecialEventsVisible, isTrafficCongestionVisible, isTrafficIncidentsVisible, isTruckWarningsVisible, isWeatherStationsVisible,
    isCyclonesVisible, isEarthquakesVisible, isLightningVisible, isPowerOutagesVisible, isStormsVisible, isStormReportsVisible, isVolcanoesVisible, isFiresVisible, isWildfiresVisible,
    showDisruptionIndex, showWildfireIndices, selectedCityState, selectedGraphView, speedIncrements, selectedSpeedMultiplierIndex, baseAnimationDelay,
    onCitySelected, onVehicleSelected, setSelectedRoute, onReloadSelectedCity, onLoadEvents, onLoadRoadStatus, onMapSelected, onReloadSelectedCityData, loadGovernmentalAlertsIfNeeded, openedPage, onAnimationSpeedChanged, onSetTileOpacity, onTrafficLayerSelected,
    onCyclonesToggled, onEarthquakesToggled, onLightningToggled, onPowerOutagesToggled, onStormsToggled, onStormReportsToggled, onVolcanoesToggled, onFiresToggled, onWildfiresToggled,
    onCycloneSelected, onEarthquakeSelected, onLightningSelected, onPowerOutageSelected, onStormSelected, onStormReportSelected, onVolcanoSelected, onFireSelected, onWildfireSelected, onClearSelectedEvent,
    onRoadStatusSelected, onRoadWorkSelected, onRoadClosureSelected, onSpecialEventSelected, onTrafficCongestionSelected, onTrafficIncidentSelected, onTruckWarningSelected, onWeatherStationSelected, onClearSelectedRoadCondition, onVehicleMessageRequested,
    onRouteRefreshRequested, onRouteRefreshErrorDismissed,
}: Required<Pick<Props, 'userData'>> & Props) => {
    const [zoomLevel, setZoomLevel] = React.useState(5);
    const [selectedChartView, setSelectedChartView] = React.useState(selectedGraphView);
    const [selectedChartKey, setSelectedChartKey] = React.useState<string>(selectedMapType.ratingKey);

    const [startPortalTour, setStartPortalTour] = React.useState(false);

    const [alerts, setAlerts] = React.useState<AlertData[]>([]);
    const [selectedAlert, setSelectedAlert] = React.useState<AlertData | undefined>(undefined);

    const [isAlertsVisible, setIsAlertsVisible] = useLocalStorageBoolean('isAlertsVisible', false);
    // state to hold the list of filtered cities actually passed to the map.
    const [filteredCities, setFilteredCities] = React.useState<LocationData[]>([]);

    // state to hold the list of filtered vehicles actually passed to the map.
    const [filteredVehicles, setFilteredVehicles] = React.useState<VehicleTrackingData[]>([]);

    // state to hold the list of filtered routes actually passed to the map.
    const [filteredRoutes, setFilteredRoutes] = React.useState<RouteData[]>([]);
    const [focusedRoute, setFocusedRoute] = React.useState<RouteData | undefined>(undefined);

    const [selectedAssetGroup, setSelectedAssetGroup] = useLocalStorage('selectedAssetGroup', 'location');

    // state to hold the list of asset types to filter by
    const [locationAssetTypeOptions, setLocationAssetTypeOptions] = React.useState<AssetType[]>(selectedAssetGroup === 'location' ? [otherAssetType] : []);
    const [locationSelectedAssetTypes, setLocationSelectedAssetTypes] = React.useState<AssetType[]>(selectedAssetGroup === 'location' ? [otherAssetType] : []);
    const [locationCountsByAssetCategory, setLocationCountsByAssetCategory] = React.useState<Record<string, number>>({});

    const [vehicleAssetTypeOptions, setVehicleAssetTypeOptions] = React.useState<AssetType[]>(selectedAssetGroup === 'vehicle' ? [otherAssetType] : []);
    const [vehicleSelectedAssetTypes, setVehicleSelectedAssetTypes] = React.useState<AssetType[]>(selectedAssetGroup === 'vehicle' ? [otherAssetType] : []);
    const [vehicleCountsByAssetCategory, setVehicleCountsByAssetCategory] = React.useState<Record<string, number>>({});

    const [routeAssetTypeOptions, setRouteAssetTypeOptions] = React.useState<AssetType[]>(selectedAssetGroup === 'route' ? [otherAssetType] : []);
    const [routeSelectedAssetTypes, setRouteSelectedAssetTypes] = React.useState<AssetType[]>(selectedAssetGroup === 'route' ? [otherAssetType] : []);
    const [routeCountsByAssetCategory, setRouteCountsByAssetCategory] = React.useState<Record<string, number>>({});

    // list of alerts to use for filter options
    const [locationAlerts, setLocationAlerts] = React.useState<string[]>([]);
    const [vehicleAlerts, setVehicleAlerts] = React.useState<string[]>([]);
    const [routeAlerts, setRouteAlerts] = React.useState<string[]>([]);

    const locationAssetTypeOptionsRef = React.useRef<AssetType[]>([]);
    const vehicleAssetTypeOptionsRef = React.useRef<AssetType[]>([]);
    const routeAssetTypeOptionsRef = React.useRef<AssetType[]>([]);
    const citiesRef = React.useRef<LocationData[]>([]);
    const vehiclesRef = React.useRef<VehicleTrackingData[]>([]);
    const routesRef = React.useRef<RouteData[]>([]);
    const timeframeRef = React.useRef<number>(0);

    const [forceCenter, setForceCenter] = React.useState<BasicLatLng | undefined>(undefined);
    const [forceZoom, setForceZoom] = React.useState<number | undefined>(undefined);
    const [forceBounds, setForceBounds] = React.useState<LatLngBounds | undefined>(undefined);

    const [polylineType] = React.useState<PolylineType>('slowdown');
    // track the route id that polylines has been loaded from the server for
    const [polylineRouteId, setPolylineRouteId] = React.useState<number | undefined>(undefined);
    const [slowdownPolylines, setSlowdownPolylines] = React.useState<SlowdownPolylineSegment[] | undefined>(undefined);
    const [roadRiskPolylines, setRoadRiskPolylines] = React.useState<RoadRiskPolylineSegment[] | undefined>(undefined);

    const [filters, setFilters] = React.useState<AssetFilter[]>([]);

    const [showModelForecastTracksForCycloneId, setShowModelForecastTracksForCycloneId] = React.useState<string | undefined>(undefined);

    const selectedCityRunTime = selectedCity?.subhourlyImpactSummary?.sourceRunTime?.getTime();
    const weatherDataRunTime = weatherData?.subhourly?.at(0)?.time.getTime();

    const [selectedTimeframeIndex, setSelectedTimeframeIndex] = React.useState(0);
    const nowcastStart = moment(citiesRef.current[0]?.subhourlyImpactSummary?.sourceRunTime);
    const nowcastEnd = moment(citiesRef.current[0]?.subhourlyImpactSummary?.sourceRunTime).add(6, 'hours');
    const timeframes: Timeframe[] = React.useMemo(() => [
        { label: 'nowcast', name: 'Nowcast', startTime: nowcastStart, endTime: nowcastEnd },
        { label: 'extended', name: 'Extended', startTime: nowcastEnd, endTime: moment().add(7, 'days') },
        { label: 'extended_alerts', name: 'Extended Alerts', startTime: nowcastEnd, endTime: moment().add(2, 'days') },
    ], [citiesRef.current[0]?.subhourlyImpactSummary?.sourceRunTime]);
    const [timeframeSelectorCollapsed, setTimeframeSelectorCollapsed] = useLocalStorageBoolean('timeframeSelectorCollapsed', false);

    React.useEffect(() => {
        function storageUpdate() {
            if (!Config.getBoolean(Config.Key.PortalTourComplete) && Config.getBoolean(Config.Key.ShownWelcomeDialog)) {
                setStartPortalTour(true);
            }
        }

        // explictly check if the tour should be started even if there wasnt a local storage update
        storageUpdate();
        document.addEventListener('localStorageUpdated', storageUpdate);

        return () => {
            document.removeEventListener('localStorageUpdated', storageUpdate);
        };
    }, []);

    // effect to prepopulate filtered cities & asset type options from the first successful load
    React.useEffect(() => {
        if (filteredCities.length === 0) {
            setFilteredCities(getFilteredLocations(userData.cities, locationSelectedAssetTypes, filters));
        }

        const allUserTypes = userData.cities.map(city => city.userMetadata ? city.userMetadata['category'] : undefined).filter(type => type !== undefined);
        const uniqueUserTypes = Array.from(new Set(allUserTypes));

        const newAssetTypeOptions = uniqueUserTypes.map((category: string) => {
            // title-case category for label
            return { label: snakeCaseToTitleCase(category), category };
        });
        setLocationAssetTypeOptions([...newAssetTypeOptions, otherAssetType]);

        const locAlerts: Set<string> = new Set();
        userData.cities.forEach(city => {
            getAssetImpactTags(city, selectedTimeframeIndex).forEach(tag => {
                if (tag.source === 'governmental_alert') locAlerts.add(tag.text);
            });
        });
        setLocationAlerts(Array.from(locAlerts));
    }, [userData.cities, userData.id,]);

    // effect to prepopulate filtered vehicles & asset type options from the first successful load
    React.useEffect(() => {
        if (filteredVehicles.length === 0) {
            setFilteredVehicles(getFilteredVehicles(userData.vehicles, vehicleSelectedAssetTypes, filters));
        }

        const allGroupIds: string[] = userData.vehicles.flatMap(vehicle => vehicle.externalGroupIds);
        const uniqueGroupIds = Array.from(new Set(allGroupIds));

        const newAssetTypeOptions = uniqueGroupIds.map((groupId: string): AssetType | undefined => {
            // TODO: clean up these 'hacks' for Ryder
            // filter out default groups from geotab
            if (groupId.startsWith('Group')) {
                return undefined;
            }

            // hardcode groups for Ryder
            if (process.env.REACT_APP_ENV === 'staging' && userData.id === 2626 || process.env.REACT_APP_ENV === 'production' && userData.id === 2715) {
                const idToNameMapping = {
                    'b2845': '04352 FL POWER N LIGHT',
                    'b27FE': '07974 True Value Atlanta',
                    'b296A': '04657 - SHELL LA',
                    'b2B89': '07350 Goodyear GA',
                    'b2893': '07367 True Value KC',
                } as any;
                return { label: idToNameMapping[groupId] || groupId, category: groupId };
            }
            // hardcode groups for JB Hunt
            if (process.env.REACT_APP_ENV === 'staging' && userData.id === 2628 || process.env.REACT_APP_ENV === 'production' && userData.id === 2720) {
                const idToNameMapping = {
                    'b27B1': 'All Vehicles',
                    'b3820': 'Trailer - OnDemand Tracking',
                } as any;
                return { label: idToNameMapping[groupId] || groupId, category: groupId };
            }
            return { label: groupId, category: groupId };
        }).filter((x): x is AssetType => x !== undefined);

        setVehicleAssetTypeOptions([...newAssetTypeOptions, otherAssetType]);

        const vehAlerts: Set<string> = new Set();
        userData.vehicles.forEach(vehicle => {
            vehicle.currentImpact?.tags.forEach(tag => {
                if (tag.source === 'governmental_alert') vehAlerts.add(tag.text);
            });
        });
        setVehicleAlerts(Array.from(vehAlerts));
    }, [userData.vehicles, userData.id]);

    // effect to prepopulate filtered routes & asset type options from the first successful load
    React.useEffect(() => {
        if (filteredRoutes.length === 0 && userData) {
            setFilteredRoutes(getFilteredRoutes(userData.savedRoutes, timeframes[selectedTimeframeIndex], routeSelectedAssetTypes, filters));
        }

        // place holder for now, might add route asset types in the funture
        setRouteAssetTypeOptions([otherAssetType]);

        const rtAlerts: Set<string> = new Set();
        userData.savedRoutes.forEach(route => {
            if (route.latestRouteResults && route.latestRouteResults.length > 0) {
                route.latestRouteResults[0].weatherAlerts.forEach(alert => {
                    rtAlerts.add(alert);
                });
            }
        });
        setRouteAlerts(Array.from(rtAlerts));
    }, [userData.savedRoutes, userData.id]);

    // effect to set the selected asset types to all options when the options change, if the user had already selected all
    // also sets the counts by asset type when the options change
    React.useEffect(() => {
        const previousAssetTypeOptions = locationAssetTypeOptionsRef.current;
        const previousSelectedAssetTypeOptions = locationSelectedAssetTypesRef.current;
        const firstLoad = previousAssetTypeOptions.length === 0;
        const hadSelectedAllPreviousOptions = previousSelectedAssetTypeOptions.length > 0 && previousAssetTypeOptions.every(assetType => previousSelectedAssetTypeOptions.find(at => at.category === assetType.category));
        if (firstLoad || hadSelectedAllPreviousOptions) {
            // select all options
            setLocationSelectedAssetTypes([...locationAssetTypeOptions]);
        }

        const haveOptionsChanged = locationAssetTypeOptions.some(assetType => previousAssetTypeOptions.find(at => at.category === assetType.category) === undefined);
        const haveCitiesChanged = filteredCities !== citiesRef.current;
        if (haveOptionsChanged || haveCitiesChanged) {
            let counts: Record<string, number> = {};
            locationAssetTypeOptions.forEach(assetType => {
                counts[assetType.category || ''] = userData.cities.filter(city => cityMatchesAssetType(city, assetType)).length;
            });

            setLocationCountsByAssetCategory(counts);
        }

        locationAssetTypeOptionsRef.current = locationAssetTypeOptions;
    }, [locationAssetTypeOptions]);

    React.useEffect(() => {
        const previousAssetTypeOptions = vehicleAssetTypeOptionsRef.current;
        const previousSelectedAssetTypeOptions = vehicleSelectedAssetTypesRef.current;
        // if the length is the same for both previous ones, then all of the options were selected
        const hadSelectedAllPreviousOptions = previousSelectedAssetTypeOptions.length > 0 && previousAssetTypeOptions.every(assetType => previousSelectedAssetTypeOptions.find(at => at.category === assetType.category));
        if (hadSelectedAllPreviousOptions) {
            // select all options
            setVehicleSelectedAssetTypes([...vehicleAssetTypeOptions]);
        }

        const haveOptionsChanged = vehicleAssetTypeOptions.some(assetType => previousAssetTypeOptions.find(at => at.category === assetType.category) === undefined);
        const haveVehiclesChanged = userData.vehicles !== vehiclesRef.current;
        if (haveOptionsChanged || haveVehiclesChanged) {
            let counts: Record<string, number> = {};
            vehicleAssetTypeOptions.forEach(assetType => {
                counts[assetType.category || ''] = userData.vehicles.filter(vehicle => vehicleMatchesAssetType(vehicle, assetType)).length;
            });

            setVehicleCountsByAssetCategory(counts);
        }

        vehicleAssetTypeOptionsRef.current = vehicleAssetTypeOptions;
    }, [vehicleAssetTypeOptions]);

    React.useEffect(() => {
        const previousAssetTypeOptions = routeAssetTypeOptionsRef.current;
        const previousSelectedAssetTypeOptions = routeSelectedAssetTypesRef.current;
        // if the length is the same for both previous ones, then all of the options were selected
        const hadSelectedAllPreviousOptions = previousSelectedAssetTypeOptions.length > 0 && previousAssetTypeOptions.every(assetType => previousSelectedAssetTypeOptions.find(at => at.category === assetType.category));
        if (hadSelectedAllPreviousOptions) {
            // select all options
            setRouteSelectedAssetTypes([...routeAssetTypeOptions]);
        }

        const haveOptionsChanged = routeAssetTypeOptions.some(assetType => previousAssetTypeOptions.find(at => at.category === assetType.category) === undefined);
        const haveRoutesChanged = userData.savedRoutes !== routesRef.current;
        if (haveOptionsChanged || haveRoutesChanged) {
            let counts: Record<string, number> = {};
            routeAssetTypeOptions.forEach(assetType => {
                counts[assetType.category || ''] = filterRoutes(userData.savedRoutes, timeframes[timeframes.length - 1]).filter(route => routeMatchesAssetType(route, assetType)).length;
            });

            setRouteCountsByAssetCategory(counts);
        }

        routeAssetTypeOptionsRef.current = routeAssetTypeOptions;
    }, [routeAssetTypeOptions]);

    const filtersRef = React.useRef<AssetFilter[]>([]);
    const locationSelectedAssetTypesRef = React.useRef<AssetType[]>([]);
    const vehicleSelectedAssetTypesRef = React.useRef<AssetType[]>([]);
    const routeSelectedAssetTypesRef = React.useRef<AssetType[]>([]);

    // effect to set filtered assets when the user selects an asset type filter
    React.useEffect(() => {
        const hasChangedLocationAssetTypes = !isEqual(locationSelectedAssetTypesRef.current, locationSelectedAssetTypes);
        const hasChangedVehicleAssetTypes = !isEqual(vehicleSelectedAssetTypesRef.current, vehicleSelectedAssetTypes);
        const hasChangedRouteAssetTypes = !isEqual(routeSelectedAssetTypesRef.current, routeSelectedAssetTypes);
        const hasChangedFilters = !isEqual(filtersRef.current, filters);
        const hasChangedCities = !isEqual(citiesRef.current, userData.cities);
        const hasChangedVehicles = !isEqual(vehiclesRef.current, userData.vehicles);
        const hasChangedRoutes = !isEqual(routesRef.current, userData.savedRoutes);
        const hasChangedTimeframes = !isEqual(timeframeRef.current, selectedTimeframeIndex);

        if (!hasChangedLocationAssetTypes && !hasChangedVehicleAssetTypes && !hasChangedRouteAssetTypes && !hasChangedFilters && !hasChangedCities && !hasChangedVehicles && !hasChangedRoutes && !hasChangedTimeframes) return;

        if (locationSelectedAssetTypes.length > 0) {
            setSelectedAssetGroup('location');
        } else if (hasChangedLocationAssetTypes && selectedCity) {
            onCitySelected(undefined);
        }

        if (vehicleSelectedAssetTypes.length > 0) {
            setSelectedAssetGroup('vehicle');
        } else if (hasChangedVehicleAssetTypes && selectedVehicle && !selectedRoute) {
            onVehicleSelected(undefined);
        }

        if (routeSelectedAssetTypes.length > 0) {
            setSelectedAssetGroup('route');
        } else if (hasChangedRouteAssetTypes && selectedRoute && !selectedVehicle) {
            setSelectedRoute(undefined);
        }

        if (hasChangedCities || hasChangedLocationAssetTypes || hasChangedFilters) {
            setFilteredCities(getFilteredLocations(userData.cities, locationSelectedAssetTypes, filters));
        }

        if (hasChangedVehicles || hasChangedVehicleAssetTypes || hasChangedFilters) {
            setFilteredVehicles(getFilteredVehicles(userData.vehicles, vehicleSelectedAssetTypes, filters));
        }

        if (hasChangedRoutes || hasChangedTimeframes || hasChangedRouteAssetTypes || hasChangedFilters) {
            setFilteredRoutes(getFilteredRoutes(userData.savedRoutes, timeframes[selectedTimeframeIndex], routeSelectedAssetTypes, filters));
        }

        locationSelectedAssetTypesRef.current = locationSelectedAssetTypes;
        vehicleSelectedAssetTypesRef.current = vehicleSelectedAssetTypes;
        routeSelectedAssetTypesRef.current = routeSelectedAssetTypes;
        filtersRef.current = filters;
        citiesRef.current = userData.cities;
        vehiclesRef.current = userData.vehicles;
        routesRef.current = userData.savedRoutes;
        timeframeRef.current = selectedTimeframeIndex;
    }, [locationSelectedAssetTypes, vehicleSelectedAssetTypes, routeSelectedAssetTypes, selectedTimeframeIndex, filters, userData.cities, userData.vehicles, userData.savedRoutes]);

    const mapCategories = getClientImpactSections(showDisruptionIndex, showWildfireIndices);
    const impactCategory = mapCategories.find(category => category.slug === 'impact-indices');
    const weatherCategory = mapCategories.find(category => category.slug === 'weather-indices');
    const radarCategory = getClientRadarSection();

    // const radarMapTypes = getClientRadarSection().mapTypes;
    const [paused, setPaused] = React.useState<boolean>(false);
    const timerRef = React.useRef<NodeJS.Timeout | undefined>(undefined);

    const [tilingLayer, setTilingLayer] = useLocalStorage('tilingLayer', undefined);

    const [trafficLayer, setTrafficLayer] = React.useState<string | undefined>(undefined);

    // const [mapBounds, setMapBounds] = React.useState<Bounds | undefined>(undefined);
    const [visibleTileset, setVisibleTileset] = React.useState<ImpactTileset | undefined>(undefined);
    const [desiredTileset, setDesiredTileset] = React.useState<ImpactTileset | undefined>(undefined);

    const [selectedLegend, setSelectedLegend] = React.useState<string | undefined>(undefined);
    const [legendCollapsed, setLegendCollapsed] = React.useState(false);
    const [currentLegends, setCurrentLegends] = React.useState<any>([]);

    const [mapBounds, setMapBounds] = React.useState<Bounds | undefined>(undefined);

    const [nearbyLightning, setNearbyLightning] = React.useState<NearbyLightning | undefined>(undefined);

    const [selectedEvent, setSelectedEvent] = React.useState<EventData | undefined>(undefined);
    const [now, setNow] = React.useState<Date>(new Date());

    const [searchQuery, setSearchQuery] = React.useState('');

    const shouldShowMapCalloutForEvent = (event: EventData) => {
        return ['volcano', 'earthquake', 'fire', 'lightning', 'storm_report', 'road_status', 'weather_station', 'power_outage', 'traffic_congestion', 'traffic_incident', 'road_work', 'road_closure', 'special_event', 'trucker_warning'].includes(event.type);
    };
    const showMapCalloutForSelectedEvent = selectedEvent && shouldShowMapCalloutForEvent(selectedEvent);

    React.useEffect(() => {
        openedPage('nowcast');
        onLoadEvents();
        onLoadRoadStatus();
        const interval = setInterval(() => {
            setNow(new Date());
        }, 60 * 1000);
        return () => clearInterval(interval);
    }, []);

    React.useEffect(() => {
        if (!selectedCity) return;
        if (userData.citiesMetadata.loading) return;
        if (selectedCityRunTime === weatherDataRunTime) return;
        if (selectedCityRunTime === undefined || weatherDataRunTime === undefined) return;
        if (selectedCityRunTime && weatherDataRunTime) {
            if (selectedCityRunTime < weatherDataRunTime) {
                onReloadSelectedCity(selectedCity, userData.token!);
            } else {
                onReloadSelectedCityData();
            }
        }
    }, [selectedCityRunTime, weatherDataRunTime]);

    // TODO: clean up the selection logic to clear all other selected items when
    // something is selected on the map?
    const clearSelectedItems = ({ chosenAlert, chosenEvent, chosenVehicle, chosenCity, chosenRoute }: { chosenAlert?: AlertData; chosenEvent?: EventData, chosenVehicle?: VehicleTrackingData, chosenCity?: LocationData, chosenRoute?: RouteData }) => {
        // if we just chose an event, but we're just going to show a callout
        // then we don't need to deselect anything else so return early
        if (chosenEvent && shouldShowMapCalloutForEvent(chosenEvent)) {
            return;
        }

        if (!chosenAlert && selectedAlert) {
            setSelectedAlert(undefined);
        }
        // we only need to deselect the event if we show the bottom large info box
        // callouts can stay (however, if another event is selected, it will remove the callout anyway)
        if (!chosenEvent && selectedEvent && !shouldShowMapCalloutForEvent(selectedEvent)) {
            setSelectedEvent(undefined);
        }
        if (!chosenVehicle && !chosenRoute && selectedVehicle) {
            onVehicleSelected(undefined);
        }
        if (!chosenCity && selectedCity) {
            onCitySelected(undefined);
        }

        if (!chosenRoute && !chosenVehicle && selectedRoute) {
            setSelectedRoute(undefined);
        }
    };
    React.useEffect(() => {
        if (selectedAlert) {
            clearSelectedItems({ chosenAlert: selectedAlert });
        }
    }, [selectedAlert]);
    React.useEffect(() => {
        if (selectedEvent) {
            clearSelectedItems({ chosenEvent: selectedEvent });
        }
    }, [selectedEvent]);
    React.useEffect(() => {
        if (selectedVehicle) {
            clearSelectedItems({ chosenVehicle: selectedVehicle });
        }
    }, [selectedVehicle]);
    React.useEffect(() => {
        if (selectedCity) {
            clearSelectedItems({ chosenCity: selectedCity });
            if (selectedCity?.id !== undefined) {
                setAlerts(getAssetAlerts(selectedCity, governmentalAlerts.value as AlertData[], selectedTimeframeIndex));
            }
        }
    }, [selectedCity]);
    React.useEffect(() => {
        if (selectedRoute) {
            clearSelectedItems({ chosenRoute: selectedRoute });
        }
    }, [selectedRoute]);

    React.useEffect(() => {
        if (selectedVehicle && !VEHICLE_CHART_VIEWS.map(v => v.key).includes(selectedChartView)) {
            // set to current conditions if it was set to something non-vehicle, otherwise keep the view the user selected
            setSelectedChartView('current_conditions');
        }
    }, [selectedVehicle?.id]);
    React.useEffect(() => {
        if (selectedCity) {
            setSelectedChartView('rating');
        }
    }, [selectedCity?.id]);

    React.useEffect(() => {
        if (isAlertsVisible) {
            loadGovernmentalAlertsIfNeeded();
        } else {
            setSelectedAlert(undefined);
        }
    }, [isAlertsVisible]);

    React.useEffect(() => {
        if (selectedCity?.id === undefined && isAlertsVisible) {
            const weatherAlerts = weatherData?.alerts ?? [];
            setAlerts(weatherAlerts);
            if (weatherAlerts.length > 0) {
                setSelectedAlert(weatherAlerts[0]);
            }
        }
    }, [weatherData?.alerts]);

    React.useEffect(() => {
        if (selectedCyclone) setSelectedEvent(selectedCyclone);
        else if (selectedEarthquake) setSelectedEvent(selectedEarthquake);
        else if (selectedLightning) setSelectedEvent(selectedLightning);
        else if (selectedPowerOutage) setSelectedEvent(selectedPowerOutage);
        else if (selectedStorm) setSelectedEvent(selectedStorm);
        else if (selectedStormReport) setSelectedEvent(selectedStormReport);
        else if (selectedVolcano) setSelectedEvent(selectedVolcano);
        else if (selectedFire) setSelectedEvent(selectedFire);
        else if (selectedWildfire) setSelectedEvent(selectedWildfire);
        else if (selectedRoadStatus) setSelectedEvent(selectedRoadStatus);
        else if (selectedWeatherStation) setSelectedEvent(selectedWeatherStation);
        else if (selectedTrafficCongestion) setSelectedEvent(selectedTrafficCongestion);
        else if (selectedTrafficIncident) setSelectedEvent(selectedTrafficIncident);
        else if (selectedRoadWork) setSelectedEvent(selectedRoadWork);
        else if (selectedRoadClosure) setSelectedEvent(selectedRoadClosure);
        else if (selectedSpecialEvent) setSelectedEvent(selectedSpecialEvent);
        else if (selectedTruckWarning) setSelectedEvent(selectedTruckWarning);
        else setSelectedEvent(undefined);
    }, [selectedCyclone, selectedEarthquake, selectedLightning, selectedPowerOutage, selectedStorm, selectedStormReport, selectedVolcano, selectedFire, selectedWildfire,
        selectedRoadStatus, selectedWeatherStation, selectedTrafficCongestion, selectedTrafficIncident, selectedRoadWork, selectedRoadClosure, selectedSpecialEvent, selectedTruckWarning]);

    React.useEffect(() => {
        if (isRoadStatusVisible) setTrafficLayer('road-status');
        else if (isRoadWorkVisible) setTrafficLayer('road-work');
        else if (isRoadClosuresVisible) setTrafficLayer('road-closures');
        else if (isTrafficCongestionVisible) setTrafficLayer('traffic');
        else if (isTrafficIncidentsVisible) setTrafficLayer('traffic-incidents');
        else if (isTruckWarningsVisible) setTrafficLayer('truck-warnings');
        else setTrafficLayer(undefined);
    }, [isRoadStatusVisible, isRoadWorkVisible, isRoadClosuresVisible, isTrafficCongestionVisible, isTrafficIncidentsVisible, isTruckWarningsVisible]);

    React.useEffect(() => {
        if (selectedEvent && !showMapCalloutForSelectedEvent) {
            onCitySelected(undefined);
            setSelectedAlert(undefined);
        }

        if (!selectedEvent) {
            onCycloneSelected(undefined);
            onEarthquakeSelected(undefined);
            onLightningSelected(undefined);
            onPowerOutageSelected(undefined);
            onStormSelected(undefined);
            onStormReportSelected(undefined);
            onVolcanoSelected(undefined);
            onFireSelected(undefined);
            onWildfireSelected(undefined);
            onRoadStatusSelected(undefined);
            onWeatherStationSelected(undefined);
        }
    }, [selectedEvent]);

    React.useEffect(() => {
        const mapCategory = mapCategories.find(x => x.key === selectedChartView);
        if (mapCategory) {
            if (mapCategory.mapTypes[0].ratingKey) {
                const mapType = mapCategory.mapTypes.find(mapType => mapType.ratingKey === mapCategory.mapTypes[0].ratingKey);
                if (mapType) {
                    onMapSelected(mapCategory, mapType);
                    if (selectedChartKey === undefined ||
                        (mapCategory.slug === 'impact-indices' && !isImpactKey(selectedChartKey)) ||
                        (mapCategory.slug === 'weather-indices' && !isWeatherKey(selectedChartKey))) {
                        setSelectedChartKey(mapType.ratingKey);
                    }
                }
            }
        }
    }, [selectedChartView]);

    React.useEffect(() => {
        const mapCategory = mapCategories.find(x => x.key === selectedChartView);
        if (mapCategory) {
            const mapType = mapCategory.mapTypes.find(mapType => mapType.ratingKey === selectedChartKey);
            if (mapType) {
                onMapSelected(mapCategory, mapType);
            }
        }
    }, [selectedChartKey, selectedTimeframeIndex]);

    type NowcastLegend = { label: string; key: string; }
    React.useEffect(() => {
        let legends: NowcastLegend[] = [];
        if (tilingLayer !== undefined && tilingLayer !== 'lightning_probability') legends.push({ label: formatRatingKey(tilingLayer) as string, key: tilingLayer as string });
        if (isAlertsVisible) legends.push({ label: 'NWS Alerts', key: 'alerts' });
        if (isRoadStatusVisible) legends.push({ label: 'Driving Conditions', key: 'road_status' });
        if (isLightningVisible) legends.push({ label: 'Lightning', key: 'lightning' });
        if (isStormReportsVisible) legends.push({ label: 'Storm Reports', key: 'storm_reports' });

        setCurrentLegends(legends);
        if (legends.length > 0 && legends.findIndex(legend => legend.key === selectedLegend) === -1) {
            setSelectedLegend(legends[0].key);
        }
    }, [tilingLayer, isAlertsVisible, isRoadStatusVisible, isLightningVisible, isStormReportsVisible]);

    React.useEffect(() => {
        tilingLayer === 'wildfire_spread' ? onWildfiresToggled(true) : onWildfiresToggled(false);

        if (tilingLayer === undefined || tilingLayer === 'lightning_probability') {
            clearTimeout(timerRef.current);
            setDesiredTileset(undefined);
            if (tilingLayer === 'lightning_probability') onLightningToggled(true);
        } else if (radarCategory && tilingLayer === 'radar') {
            onMapSelected(radarCategory, radarCategory.mapTypes[0]);
        } else if (impactCategory && isImpactKey(tilingLayer)) {
            const matchedMap = impactCategory?.mapTypes?.find(map => map.ratingKey === tilingLayer);
            if (matchedMap) {
                onMapSelected(impactCategory, matchedMap);
            }
        } else if (weatherCategory && isWeatherKey(tilingLayer)) {
            const matchedMap = weatherCategory.mapTypes?.find(map => map.ratingKey === tilingLayer);
            if (matchedMap) {
                onMapSelected(weatherCategory, matchedMap);
            }
        }
    }, [tilingLayer]);

    React.useEffect(() => {
        // clear out any auto playback timer
        clearTimeout(timerRef.current);

        //selectedMapType is not the same as tiling layer, maptype set my chart selection
        if (tilingLayer !== selectedMapType.ratingKey) {
            return;
        }

        if (selectedMapType === undefined) {
            console.log('[Nowcast] selectedMapType is undefined');
            return;
        }

        if (tilingLayer === undefined) {
            console.log('[Nowcast] tilingLayer is undefined: setting desired tileset to undefined');
            setDesiredTileset(undefined);
            return;
        }

        //onMapSelected(isImpactKey(selectedMapType.ratingKey) ? mapCategories[0] : mapCategories[1], selectedMapType);

        // try to keep same time index
        const tilesets = getTilesets(tilingLayer);
        let newDesiredTileset: ImpactTileset | undefined = undefined;
        if (tilesets && desiredTileset) {
            newDesiredTileset = TilesetUtils.getTileset(tilesets, desiredTileset.time, { allowNearestMatch: true });
        }
        console.log('[Nowcast] selectedMapType effect: setting desired tileset to', newDesiredTileset ?? tilesets?.[0]);
        setDesiredTileset(newDesiredTileset ?? tilesets?.[0]);

        // re-run this effect when selectedTimeframeIndex changes so that we update our desiredTileset
        // to be from the new list of tiles
        // re-run this effect when the tilesets load in because otherwise, it can fail to get a tileset
        // because they haven't loaded from the server and since the selectedMapType won't change
        // when that happens so we need to explicitly re-try once they are no longer undefined
    }, [selectedMapType, selectedTimeframeIndex, subhourlyImpactTilesets === undefined, impactTilesets === undefined, subhourlyWeatherTilesets === undefined, weatherTilesets === undefined]);

    React.useEffect(() => {
        if (!isLightningVisible || selectedCity === undefined) {
            setNearbyLightning(undefined);
            return;
        }

        let within10 = 0;
        let within20 = 0;
        let within30 = 0;
        let closestStrike: LightningData | undefined = undefined;
        let closestDistance: number | undefined = undefined;
        const thirtyMinsAgo = moment().subtract(30, 'm');
        lightning?.forEach((strike: LightningData) => {
            if (moment(strike.detectedTime) < thirtyMinsAgo) {
                return;
            }

            const point = getPointForEvent(strike);
            const distance = haversineDistance({ lat: selectedCity.latitude, lng: selectedCity.longitude }, { lat: point.latitude, lng: point.longitude }, true);
            if (distance <= 30) {
                if (distance <= 10) {
                    within10 += 1;
                } else if (distance <= 20) {
                    within20 += 1;
                } else if (distance <= 30) {
                    within30 += 1;
                }

                if (closestDistance === undefined || closestDistance > distance) {
                    closestDistance = distance;
                    closestStrike = strike;
                }
            }
        });
        setNearbyLightning({
            within10Miles: within10,
            within20Miles: within20,
            within30Miles: within30,

            closestStrike,
            closestDistance,
        });
    }, [selectedCity, isLightningVisible, lightning]);

    React.useEffect(() => {
        if (selectedRoute) {
            if (polylineRouteId === selectedRoute.id || userData?.token === undefined) {
                return;
            }

            requestWrapper(() => fetch(`${API_HOST}/routes/${selectedRoute.id}.json?token=${userData.token}`))
                .then(response => response.json())
                .then((data: any) => {
                    const route = unmarshalRoute(data.route);

                    let slowdownPolylines: SlowdownPolylineSegment[] | undefined = undefined;
                    let roadRiskPolylines: RoadRiskPolylineSegment[] | undefined = undefined;
                    if (route.latestRouteResults !== undefined) {
                        slowdownPolylines = [];
                        roadRiskPolylines = [];
                        // reversing the order of the route options polylines so when they overlap they are drawn with lower options on top (option 1 on top of option 2)
                        // when the overlapping segment of polylines is clicked the top option is selected
                        route.latestRouteResults.slice().reverse().forEach((routeResult, i) => {
                            // the index needs to reversed since the iteration is reversed so i = 0 is the last route option
                            // realRouteIndex is 1-indexed up to length
                            const realRouteIndex = route!.latestRouteResults!.length - i;
                            routeResult.slowdownPolylines?.forEach((polyline) => {
                                const routeClone = { ...route };
                                routeClone.selectedRouteOption = `${routeClone.id} Option ${realRouteIndex}`;
                                slowdownPolylines!.push({ ...polyline, route: routeClone });
                            });
                            routeResult.roadRiskPolylines?.forEach((polyline) => {
                                const routeClone = { ...route };
                                routeClone.selectedRouteOption = `${routeClone.id} Option ${realRouteIndex}`;
                                roadRiskPolylines!.push({ ...polyline, route: routeClone });
                            });
                        });
                    }

                    setPolylineRouteId(route.id);
                    setSlowdownPolylines(slowdownPolylines);
                    setRoadRiskPolylines(roadRiskPolylines);
                })
                .catch(console.error);
        } else {
            setPolylineRouteId(undefined);
            setSlowdownPolylines(undefined);
            setRoadRiskPolylines(undefined);
        }
    }, [selectedRoute?.id]);

    const getTilesets = (currentTilingLayer: string | undefined): ImpactTileset[] | undefined => {
        if (!currentTilingLayer) {
            return undefined;
        }
        // use subhourly tiles for Nowcast (selectedTimeframeIndex = 0), hourly tiles for Extended (selectedTimeframeIndex = 1)
        let tilesets: ImpactTileset[] | undefined = selectedTimeframeIndex === 0 ? subhourlyImpactTilesets?.[currentTilingLayer] : impactTilesets?.[currentTilingLayer];
        if (tilesets === undefined) {
            tilesets = selectedTimeframeIndex === 0 ? subhourlyWeatherTilesets?.[currentTilingLayer] : weatherTilesets?.[currentTilingLayer];
        }
        // fallback for wildfire spread which is only in hourly tiles
        if (tilesets === undefined && currentTilingLayer === 'wildfire_spread') {
            tilesets = impactTilesets?.[currentTilingLayer];
        }
        if (!tilesets) {
            return undefined;
        }
        if (currentTilingLayer === 'radar') {
            return tilesets;
        }

        if (currentTilingLayer === 'wildfire_spread') {
            return tilesets.slice(0, 1);
        }

        const currentTime = new Date().getTime();
        const startTime = Math.floor(currentTime / 900000) * 900000;
        let forecastHours = selectedTimeframeIndex === 0 ? 6 : currentTilingLayer === 'wildfire_conditions' ? 144 : 168;

        // for nowcast, we start at now
        // for extended, we start at 6 hours after now (end of nowcast)
        const startTimeInclusive = selectedTimeframeIndex === 0 ? startTime : startTime + 6 * 3600000;
        const endTimeInclusive = startTime + forecastHours * 3600000;
        // chop off any times from the model run that are later than endTimeInclusive
        return tilesets.filter((tileset: ImpactTileset) => tileset.time.getTime() >= startTimeInclusive && tileset.time.getTime() <= endTimeInclusive);
    };

    const radarLegends = [{
        label: 'Rain', image: 'Radar_TWC_Rain'
    }, {
        label: 'Snow', image: 'Radar_TWC_Snow'
    }];

    let radarLegendElements: JSX.Element | JSX.Element[][] = radarLegends.map(legend => {
        return [
            (<img style={{ maxWidth: '450px' }} key={legend.label} alt={legend.label} src={`/legends/${legend.image}.svg`} />)
        ];
    });

    const radarLegend = (<div>
        <div className={'impact-tile-legend'} style={{ maxWidth: '500px', padding: "10px 15px 10px 15px" }}>{radarLegendElements}</div>
    </div>);

    let tileLegendElements: JSX.Element | undefined = undefined;
    if ((tilingLayer && tilingLayer !== 'radar') && Object.keys(blurbs).length > 0) {
        tileLegendElements = (
            <div>
                <div className={'impact-tile-legend'} style={{ "padding": "10px 15px 10px 0px" }}>
                    <LegendComponent
                        ratingKey={tilingLayer as RatingKey}
                        blurbs={selectedMapType !== undefined ? blurbs[tilingLayer] : []}
                        skipFirstBlurb={true}
                        embedValues={isImpactKey(tilingLayer)}
                        tileOpacity={tileOpacity}
                        setTileOpacity={(tileOpacity) => onSetTileOpacity(tileOpacity)}
                    />
                </div>
            </div>
        );
    }

    const tileLegend = (<div style={{ "padding": "10px 15px 15px 0px" }}>
        <div>{tileLegendElements}</div>
    </div>);

    const goToPreviousTimeOffset = () => {
        if (!desiredTileset || !tilingLayer) {
            return;
        }
        setDesiredTileset(TilesetUtils.getPreviousTileset(getTilesets(tilingLayer), desiredTileset.time));
    };

    const goToNextTimeOffset = () => {
        if (!desiredTileset || !tilingLayer) {
            return;
        }
        // there shouldn't be a case where we want to go to the next time offset
        // when our tilingLayer variable doesn't match our desired tileset variable
        if (tilingLayer !== desiredTileset.variable) {
            console.log('[Nowcast] skipping goToNextTimeOffset because variables are not matching', tilingLayer, desiredTileset);
            return;
        }
        const nextTileset = TilesetUtils.getNextTileset(getTilesets(tilingLayer), desiredTileset.time);
        console.log('[Nowcast] goToNextTimeOffset for', tilingLayer, 'is loading:', nextTileset);
        setDesiredTileset(nextTileset);
    };

    const goToDate = (date: Date) => {
        pause();
        setDesiredTileset(TilesetUtils.getTileset(tilesets, date));
    };

    const resume = () => {
        clearTimeout(timerRef.current);

        timerRef.current = setTimeout(() => goToNextTimeOffset(), 1000);

        setPaused(false);
    };

    const pause = () => {
        clearTimeout(timerRef.current);
        setPaused(true);
    };

    const rewind = () => {
        pause();
        goToPreviousTimeOffset();
    };

    const fastForward = () => {
        pause();
        goToNextTimeOffset();
    };

    const skipToFirst = () => {
        const tilesets = getTilesets(tilingLayer);
        if (tilesets) {
            pause();
            setDesiredTileset(tilesets[0]);
        }
    };

    const skipToLast = () => {
        const tilesets = getTilesets(tilingLayer);
        if (tilesets) {
            pause();
            setDesiredTileset(tilesets[tilesets.length - 1]);
        }
    };

    const cycleSpeedIncrement = () => {
        let speedMultiplierIndex = (selectedSpeedMultiplierIndex + 1) % speedIncrements.length;
        let speedMultiplier = speedIncrements[speedMultiplierIndex];
        let animationDelay = baseAnimationDelay / speedMultiplier;

        onAnimationSpeedChanged(speedMultiplierIndex, animationDelay);
    };

    const onUserSelectedCity = (city: LocationData) => {
        onCitySelected(city);
        // set tiling Chart view to source of highest impact (that is HYPERR)
        const impactTags = getAssetImpactTags(city, selectedTimeframeIndex);
        if (tilingLayer === undefined) {
            if (impactTags) {
                for (const tag of impactTags) {
                    if (tag.source !== 'wo_storms') {
                        impactTagSelected(tag);
                        break;
                    }
                }
            }
        }
    };

    const onMapClicked = (coord: google.maps.LatLng) => {
        // only allowing map click to select alerts
        if (!isAlertsVisible) return;

        let city: LocationData = {
            id: undefined,
            zip: '00000',
            name: '',
            latitude: coord.lat(),
            longitude: coord.lng(),
            needsGeocoding: true,
            impactSummaries: []
        };

        onCitySelected(city);
    };

    const impactTagSelected = (tag: ImpactTag) => {
        if (tag.source === 'HYPERR' || tag.source === 'hourly') {
            if (isImpactKey(tag.variable)) setSelectedChartView('rating');
            if (isWeatherKey(tag.variable)) setSelectedChartView('weather');
            setSelectedChartKey(tag.variable!);
        } else if (tag.source === 'governmental_alert') {
            setSelectedChartView('alerts');
        } else if (tag.source === 'wo_storms') {
            onStormSelected(storms?.find(storm => storm.id === tag.id));
        }
    };

    let tilingLayerLegend: JSX.Element | undefined = undefined;
    if (tilingLayer !== undefined) {
        tilingLayerLegend = tilingLayer === 'radar' ? radarLegend : tileLegend;
    }

    let alertsLegend = (userData.portalToken && <AlertsLegend
        portalToken={userData.portalToken}
        mapBounds={mapBounds}
        className="dashboard"
    />);

    const roadStatusLegend = (
        <div className={"dashboard-alerts-legend"} style={{ "padding": "10px 15px 15px 0px", backgroundColor: cardBackgroundColor }}>
            <div>
                <div style={{ "padding": "0px 25px 15px 25px", backgroundColor: cardBackgroundColor }}>
                    <RoadStatusLegendComponent />
                </div>
            </div>
        </div>
    );

    let visibleStormReportsforLegend = stormReports || [];
    if (isStormReportsVisible && mapBounds) {
        const currentViewport: ViewportData = { neLatitude: mapBounds.ne.lat, neLongitude: mapBounds.ne.lng, swLatitude: mapBounds.sw.lat, swLongitude: mapBounds.sw.lng };
        visibleStormReportsforLegend = visibleStormReportsforLegend.filter(stormReport => {
            const point = getPointForEvent(stormReport);
            return isLatLngInViewport({ lat: point.latitude, lng: point.longitude }, currentViewport);
        });
    }

    const stormReportsLegend = (
        <div className={"dashboard-alerts-legend"} style={{ backgroundColor: cardBackgroundColor }}>
            <StormReportsLegendComponent stormReports={visibleStormReportsforLegend} />
        </div>
    );

    const lightningBlurbs: Blurb[] = [30, 25, 20, 15, 10, 5, 0].map(minutesAgo => {
        return {
            blurb: '',
            color: '#00A6CA' + Math.floor(calculateLightningOpacityFromAge(minutesAgo) * 255).toString(16).padStart(2, '0'),
            value: minutesAgo,
        };
    });

    const lightningLegend = (
        <div className={"dashboard-alerts-legend"} style={{ backgroundColor: cardBackgroundColor, paddingTop: '10px', display: 'flex', flexDirection: 'column' }}>
            <div style={{ padding: '10px 10px 0px 10px', display: 'flex' }}>
                <div style={{ margin: '10px', justifyContent: 'start', display: 'flex', }}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography sx={{ fontSize: 16, fontWeight: 'bold' }}>Lightning Distance</Typography>
                        <Typography sx={{ fontSize: 16, fontWeight: 'bold' }}>and Count</Typography>
                    </div>
                    {nearbyLightning && <div style={{ display: 'flex' }}>
                        <div style={{ marginLeft: '30px', display: 'flex', flexDirection: 'column' }}>
                            <Typography sx={{ textAlign: 'center', fontSize: 16 }}>&lt; 10 miles</Typography>
                            <Typography sx={{ textAlign: 'center', fontSize: 16, color: "red" }}>{nearbyLightning.within10Miles} strikes</Typography>
                        </div>
                        <div style={{ marginLeft: '30px', display: 'flex', flexDirection: 'column' }}>
                            <Typography sx={{ textAlign: 'center', fontSize: 16 }}>&lt; 20 miles</Typography>
                            <Typography sx={{ textAlign: 'center', fontSize: 16, color: "orange" }}>{nearbyLightning.within20Miles} strikes</Typography>
                        </div>
                        <div style={{ marginLeft: '30px', display: 'flex', flexDirection: 'column' }}>
                            <Typography sx={{ textAlign: 'center', fontSize: 16 }}>&lt; 30 miles </Typography>
                            <Typography sx={{ textAlign: 'center', fontSize: 16, color: "yellow" }}>{nearbyLightning.within30Miles} strikes</Typography>
                        </div>
                        {nearbyLightning.closestStrike && nearbyLightning.closestDistance && <div style={{ marginLeft: '30px', display: 'flex', flexDirection: 'column', borderLeft: 'thin solid #919191', paddingLeft: '30px' }}>
                            <Typography sx={{ textAlign: 'center', fontSize: 16 }}>Closest</Typography>
                            <Typography sx={{ textAlign: 'center', fontSize: 16, color: nearbyLightning.closestDistance <= 10 ? "red" : nearbyLightning.closestDistance <= 20 ? "orange" : "yellow" }}>{nearbyLightning.closestDistance.toFixed(1)} miles away</Typography>
                        </div>}
                    </div>}
                    {!nearbyLightning && <div style={{ padding: '0px 40px', textAlign: 'center', alignContent: 'center' }}>
                        <Typography sx={{ fontSize: 16, color: '#919191' }}>Select a location to see nearby lightning</Typography>
                    </div>}
                </div>
            </div>
            <div style={{ margin: '0 20px 10px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography sx={{ fontSize: 12, color: '#919191' }}>Data shown for the last 30 minutes</Typography>
                <div className={'impact-tile-legend'}>
                    <LegendComponent
                        blurbs={lightningBlurbs}
                        skipFirstBlurb={false}
                        embedValues={true}
                        legendTitle="Minutes ago"
                    />
                </div>
            </div>
        </div>
    );

    let legendContainer: JSX.Element | undefined = undefined;
    if (currentLegends.length > 0) {
        legendContainer = (
            // 544px = 80px (width of left tabs) + 12px (margin) + 12px (margin) + 450px (pop up width when clicked)
            <Paper elevation={3} style={{ backgroundColor: cardBackgroundColor, borderRadius: 11, backgroundImage: 'none', pointerEvents: 'auto' }}>
                <header style={{ display: "flex", justifyContent: "space-between", paddingTop: currentLegends.length === 0 ? '20px' : '0px', paddingLeft: '8px' }}>
                    <React.Fragment>
                        <Tabs
                            className="DashboardTabs"
                            value={selectedLegend}
                            onChange={(event, newLegend) => setSelectedLegend(newLegend)}
                            indicatorColor={'primary'}
                            textColor={'primary'}
                            variant="scrollable"
                            scrollButtons="auto"
                            style={{ height: 40, width: '100%' }}
                        >
                            {currentLegends.map((legend: any) => <Tab label={legend.label} value={legend.key} />)}
                        </Tabs >
                        <IconButton
                            color={'primary'}
                            onClick={() => setLegendCollapsed(!legendCollapsed)}
                            style={{ height: 40 }}
                        >
                            {legendCollapsed ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
                        </IconButton>
                    </React.Fragment>
                </header>
                {currentLegends.length === 0 &&
                    <div style={{ padding: '10px 8px' }}>
                        <Typography style={{ marginLeft: "15px" }} variant={'body2'} color={'textSecondary'}>Track critical weather impact over the next 6 hours.</Typography>
                        <Typography style={{ marginLeft: "15px" }} variant={'body2'} color={'textSecondary'}>Data powered by HYPERR, our high resolution short term model.</Typography>
                        <Typography style={{ marginLeft: "15px" }} variant={'body2'} color={'textSecondary'}>Updates every 15 minutes. Covers the contiguous US.</Typography>
                    </div>}
                {!legendCollapsed && isAlertsVisible && selectedLegend === 'alerts' && alertsLegend}
                {!legendCollapsed && tilingLayer !== undefined && tilingLayer !== 'lightning_probability' && (isImpactKey(selectedLegend) || isWeatherKey(selectedLegend) || selectedLegend === 'radar') && tilingLayerLegend}
                {!legendCollapsed && isRoadStatusVisible && selectedLegend === 'road_status' && roadStatusLegend}
                {!legendCollapsed && isLightningVisible && selectedLegend === 'lightning' && lightningLegend}
                {!legendCollapsed && isStormReportsVisible && selectedLegend === 'storm_reports' && stormReportsLegend}
            </Paper >
        );
    }

    const nowcastDescription = <>
        See a 6-hour forecast in 15-minute chunks.
        <br />
        Powered by HYPERR, our proprietary high-resolution model.
    </>;

    const extendedForecastDescription = <>
        See a 7-day forecast in 1-hour chunks.
        <br />
        Extended forecast begins after nowcast ends.
    </>;

    const timeframeSelectorComponent: JSX.Element = (
        // 544px = 80px (width of left tabs) + 12px (margin) + 12px (margin) + 450px (pop up width when clicked)
        <Paper className="NowcastTimeframeSelector" elevation={3} style={{ backgroundColor: cardBackgroundColor, borderRadius: 11, width: '410px', marginTop: legendContainer === undefined ? '0px' : '5px', backgroundImage: 'none', pointerEvents: 'auto' }}>
            <header style={{ display: "flex", justifyContent: "space-between", paddingLeft: '20px' }}>
                <Typography style={{ marginTop: 15, marginBottom: 15, fontSize: 18, fontWeight: 'bold' }} variant={'body1'}>Forecast Type</Typography>
                {timeframeSelectorCollapsed && <RadioGroup
                    row
                    value={selectedTimeframeIndex}
                    onChange={(event, value) => setSelectedTimeframeIndex(parseInt(value))}
                    style={{ marginLeft: '10px' }}
                >
                    <Tooltip title={nowcastDescription}>
                        <FormControlLabel
                            key={'nowcast'}
                            value={0}
                            control={<Radio size="small" />}
                            label={<Typography sx={{ fontSize: 13 }}>Nowcast</Typography>}
                            style={{ opacity: selectedTimeframeIndex === 0 ? 1 : 0.8 }}
                        />
                    </Tooltip>
                    <Tooltip title={extendedForecastDescription}>
                        <FormControlLabel
                            key={'extended'}
                            value={1}
                            control={<Radio size="small" />}
                            label={<Typography sx={{ fontSize: 13 }}>Extended</Typography>}
                            style={{ opacity: selectedTimeframeIndex === 1 ? 1 : 0.8 }}
                        />
                    </Tooltip>
                </RadioGroup>}
                <IconButton
                    color={'primary'}
                    onClick={() => setTimeframeSelectorCollapsed(!timeframeSelectorCollapsed)}
                    style={{ height: 40, marginTop: 8, marginRight: 8 }}
                >
                    {timeframeSelectorCollapsed ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
                </IconButton>
            </header>
            {!timeframeSelectorCollapsed && <RadioGroup
                value={selectedTimeframeIndex}
                onChange={(event, value) => setSelectedTimeframeIndex(parseInt(value))}
            >
                <FormControlLabel
                    key={'nowcast'}
                    value={0}
                    control={<Radio size="small" />}
                    label={
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <Typography sx={{ fontSize: 15, lineHeight: '18px', fontWeight: 'bold' }} variant="body1">Nowcast</Typography>
                            <Typography sx={{ fontSize: 11, lineHeight: '13px' }} variant="caption">{nowcastDescription}</Typography>
                        </div>
                    }
                    style={{ marginLeft: 12, marginBottom: 10, opacity: selectedTimeframeIndex === 0 ? 1 : 0.8 }}
                />
                <FormControlLabel
                    key={'extended'}
                    value={1}
                    control={<Radio size="small" />}
                    label={
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <Typography sx={{ fontSize: 15, lineHeight: '18px', fontWeight: 'bold' }} variant="body1">Extended Forecast</Typography>
                            <Typography sx={{ fontSize: 11, lineHeight: '13px' }} variant="caption">{extendedForecastDescription}</Typography>
                        </div>
                    }
                    style={{ marginLeft: 12, marginBottom: 21, opacity: selectedTimeframeIndex === 1 ? 1 : 0.8 }}
                />
            </RadioGroup>}
        </Paper >
    );

    const topLeftContainer: JSX.Element = (
        <div style={{ position: 'absolute', top: 8, left: 12, pointerEvents: 'none', maxWidth: 'min(800px, calc(100% - 544px))', }}>
            {legendContainer}
            {selectedAssetGroup !== 'vehicle' && timeframeSelectorComponent}
        </div>
    );

    let assetsLoadingSpinner: JSX.Element | undefined = undefined;
    if (userData.citiesMetadata.loading || userData.vehiclesMetadata.loading || userData.savedRoutesMetadata?.loading) {
        assetsLoadingSpinner = (
            <Paper elevation={4} style={{ backgroundColor: cardBackgroundColor, backgroundImage: 'none', position: "absolute", top: 7, right: 185, display: "flex", flexDirection: "row", alignItems: "center" }}>
                <CircularProgress size={20} style={{ margin: "14px 14px" }} color="inherit" />
                <Typography variant={'body1'} style={{ marginRight: "14px" }}>Loading assets...</Typography>
            </Paper>
        );
    }

    let selectedAlertContainer: JSX.Element | undefined = undefined;
    if (selectedAlert) {
        const startTime = new Date(selectedAlert.timestamps.begins);
        const startDatetimeString = startTime.toLocaleDateString('en-US', { weekday: 'short', month: 'short', day: 'numeric' })
            + ', '
            + startTime.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true, timeZoneName: 'short' });
        const endTime = new Date(selectedAlert.timestamps.expires);
        const endDatetimeString = endTime.toLocaleDateString('en-US', { weekday: 'short', month: 'short', day: 'numeric', })
            + ', '
            + endTime.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true, timeZoneName: 'short' });
        selectedAlertContainer = (
            <Paper className={"AssetGraphComponent"} elevation={3} style={{ backgroundColor: cardBackgroundColor, backgroundImage: 'none', borderRadius: '0px 11px 11px 0px' }}>
                <header style={{ padding: '12px 12px 12px 0px', display: 'flex', flexDirection: 'row', alignItems: 'center', }}>
                    <Typography style={{ marginLeft: "12px" }} variant={'body1'}>Details</Typography>
                    <div style={{ flex: '1 1 0' }} />
                    <IconButton
                        color={'primary'}
                        onClick={() => {
                            setSelectedAlert(undefined);
                        }}
                        style={{ height: 30 }}
                    >
                        <Close />
                    </IconButton>
                </header>
                <div style={{ height: '235px', padding: '0px 12px 12px 12px', overflowY: 'auto' }}>
                    <Typography variant={'body2'} color={'textPrimary'}>Start Time: {startDatetimeString}</Typography>
                    <Typography variant={'body2'} color={'textPrimary'}>End Time: {endDatetimeString}</Typography>
                    {selectedAlert?.details.body}
                </div>
            </Paper>
        );
    }

    let selectedEventContainer: JSX.Element | undefined = undefined;
    if (selectedEvent && !showMapCalloutForSelectedEvent) {
        let selectedEventType: string | undefined = undefined;
        if (selectedCyclone) selectedEventType = 'cyclone';
        else if (selectedPowerOutage) selectedEventType = 'powerOutage';
        else if (selectedStorm) selectedEventType = 'storm';
        else if (selectedWildfire) selectedEventType = 'wildfire';
        selectedEventContainer = (
            <Paper className={"AssetGraphComponent"} elevation={3} style={{ backgroundColor: cardBackgroundColor, backgroundImage: 'none', borderRadius: '0px 11px 11px 0px' }}>
                <header style={{ padding: '12px 12px 12px 0px', display: 'flex', flexDirection: 'row', alignItems: 'center', }}>
                    <Typography style={{ marginLeft: "15px" }} variant={'body1'}>Details</Typography>
                    <div style={{ flex: '1 1 0' }} />
                    <IconButton
                        color={'primary'}
                        onClick={() => {
                            setSelectedEvent(undefined);
                        }}
                        style={{ height: 30 }}
                    >
                        <Close />
                    </IconButton>
                </header>
                <div style={{ height: '235px', padding: '0px 12px 12px 12px', overflowY: 'auto' }} >
                    {selectedCyclone && selectedCyclone.hasModelForecastTracks && <React.Fragment>
                        <FormControlLabel
                            control={<Switch
                                checked={showModelForecastTracksForCycloneId === selectedCyclone.cycloneId}
                                onChange={event => setShowModelForecastTracksForCycloneId(event.target.checked ? selectedCyclone?.cycloneId : undefined)}
                            />}
                            label="Model Forecast Tracks"
                            labelPlacement="start"
                            sx={{ marginLeft: '0px' }}
                        />
                    </React.Fragment>}
                    <div dangerouslySetInnerHTML={{ __html: getEventDescription(selectedEvent, selectedEventType!) }} />
                </div>
            </Paper>
        );
    }

    let mapControls: JSX.Element | undefined = undefined;
    let displayedTime: Date | undefined = desiredTileset?.time;
    const tilesets = getTilesets(tilingLayer);
    if (tilingLayer !== undefined && tilingLayer !== 'lightning_probability' && tilesets && tilesets.length > 1) {
        const speedIncrement = speedIncrements[selectedSpeedMultiplierIndex];
        mapControls = (
            <MapControlsComponent
                paused={paused}
                speedIncrement={speedIncrement}
                times={tilesets.map(x => x.time)}
                displayedTime={displayedTime}
                loading={visibleTileset?.id !== desiredTileset?.id}
                horizontalLayout={true}

                onPause={() => pause()}
                onResume={() => resume()}
                onFastForward={() => fastForward()}
                onRewind={() => rewind()}
                onSkipToFirst={() => skipToFirst()}
                onSkipToLast={() => skipToLast()}
                onCycleSpeedIncrement={() => cycleSpeedIncrement()}
                setSelectedDate={(date: Date) => goToDate(date)}
            />
        );
    }

    let timeSeriesGraphContainer: JSX.Element | undefined = undefined;
    let selectedLocationInfo: JSX.Element | undefined = undefined;
    // only valid when saved locations (selectedCity.id !== undefined) are selected 
    if (selectedAssetGroup === 'location' && selectedCity?.id !== undefined) {
        const timezone = selectedCity?.timezone;
        // use hourly data for extened forecast or for global locations that do not have subhourly data
        const currentRatingData: RatingsDataWithLocationInfo = Object.assign({}, selectedTimeframeIndex === 0 && selectedCity.subhourlyImpactSummary?.source === 'HYPERR' ? subhourlyRatingsData : ratingsData);
        let currentWeatherData = selectedTimeframeIndex === 0 && selectedCity.subhourlyImpactSummary?.source === 'HYPERR' ? weatherData?.subhourly : weatherData?.hourly;
        let filteredRatingsData: RatingsDataWithLocationInfo = Object.assign({}, currentRatingData);
        let filteredWeatherData: WeatherConditions[] = [];
        if (currentWeatherData) {
            const startIndex = currentWeatherData!.findIndex(wc => wc.time.getTime() > timeframes[selectedTimeframeIndex].startTime.valueOf());
            const endIndex = currentWeatherData!.findIndex(wc => wc.time.getTime() > timeframes[selectedTimeframeIndex].endTime.valueOf());
            filteredWeatherData = currentWeatherData.slice(startIndex, endIndex);
        }

        if (currentRatingData) {
            let startIndex = undefined;
            let endIndex = undefined;
            for (const key of ALL_RATING_KEYS) {
                if (currentRatingData[key] !== undefined && currentRatingData[key].length > 0) {
                    startIndex = currentRatingData[key].findIndex((ri: any) => ri.time.getTime() > timeframes[selectedTimeframeIndex].startTime.valueOf());
                    endIndex = currentRatingData[key].findIndex((ri: any) => ri.time.getTime() > timeframes[selectedTimeframeIndex].endTime.valueOf());
                }
                if (filteredRatingsData[key] && startIndex >= 0 && endIndex >= 0) {
                    filteredRatingsData[key] = filteredRatingsData[key].slice(startIndex, endIndex);
                }
            }
        }

        const chartViews = mapCategories.map(category => { return { key: category.key, title: category.title }; });
        chartViews.push({ key: 'alerts', title: 'NWS Alerts' });

        timeSeriesGraphContainer = selectedChartView === 'alerts' ?
            (<AlertTimelineView
                selectedChartView={selectedChartView}
                chartViews={chartViews}
                alerts={alerts}
                timeframe={selectedTimeframeIndex === 0 ? timeframes[selectedTimeframeIndex] : timeframes[selectedTimeframeIndex + 1]}
                timezone={timezone}
                onCloseClicked={() => onCitySelected(undefined)}
                setSelectedChartView={(view) => setSelectedChartView(view)}
                setSelectedAlert={(alert) => setSelectedAlert(alert)}
            />) : (
                <TimeSeriesGraphView
                    isNowcastGraph={true}
                    zoomDisabled={selectedTimeframeIndex === 0}
                    selectedCityState={selectedCityState}
                    selectedGraphView={selectedChartView}
                    graphViews={chartViews}
                    onValueClicked={(date) => goToDate(date)}
                    onCloseClicked={() => onCitySelected(undefined)}
                    setSelectedGraphView={(view) => setSelectedChartView(view)}
                    setTilingLayer={(layer) => setTilingLayer(layer)}

                    selectedWeatherProperty={selectedChartKey}
                    onWeatherPropertyChanged={ratingKey => setSelectedChartKey(ratingKey)}
                    weatherConditions={filteredWeatherData}

                    showDisruptionIndex={showDisruptionIndex}
                    showWildfireIndices={showWildfireIndices}
                    ratings={filteredRatingsData}
                    timezone={timezone}
                    blurbs={blurbs}
                    now={now}
                    selectedRatingKey={selectedChartKey as RatingKey}
                    onRatingKeySelected={ratingKey => setSelectedChartKey(ratingKey)}
                />
            );

        let impactLevel = getAssetImpactLevel(selectedCity, selectedTimeframeIndex);
        let impactString = `${wordForImpactLevel(impactLevel)}`;
        let impactTags: ImpactTag[] = getAssetImpactTags(selectedCity, selectedTimeframeIndex);
        selectedLocationInfo = (
            <Paper className="selected-asset-info" elevation={4} style={{ backgroundColor: cardBackgroundColor, display: 'flex', flexDirection: 'column' }}>
                <Typography style={{ margin: "10px 0px 0px 15px" }} variant={'subtitle1'} fontWeight={"bold"} color={'textPrimary'}> {selectedCity?.name}</Typography>
                <Typography style={{ margin: "0px 0px 5px 15px", opacity: 0.5 }} variant={'caption'} >{selectedTimeframeIndex === 0 ? `6 Hour Forecast - Powered by HYPERR` : `${24 * selectedTimeframeIndex} Hour Forecast`}</Typography>
                <div style={{ overflow: 'auto', height: '100%' }}>
                    <Typography style={{ margin: "5px 15px" }} variant={'body2'} fontWeight={"bold"} color={'textPrimary'}>Max Impact Level</Typography>
                    <div style={{ margin: "5px 15px 10px", display: 'flex', flexDirection: 'row', alignContent: 'center' }}>
                        <Chip
                            style={{
                                padding: 8,
                                // subhourlyImpactSummary is missing if you click on the map on impact tab then switch to nowcast and click on a saved location
                                backgroundColor: darkTagColorForImpactLevel(getAssetImpactLevel(selectedCity, selectedTimeframeIndex)),
                                color: 'white',
                                fontWeight: 'bold',
                            }}
                            label={impactString}
                        />
                    </div>
                    {/* subhourlyImpactSummary is missing if you click on the map on impact tab then switch to nowcast and click on a saved location */}
                    {getAssetImpactLevel(selectedCity, selectedTimeframeIndex) > 0 && <React.Fragment>
                        <Typography style={{ margin: "5px 15px" }} fontWeight={"bold"} variant={'body2'} color={'textPrimary'}>Specific Risks ({impactTags.length})</Typography>
                        <div>
                            {impactTags.map(tag => (
                                <div>
                                    <Chip
                                        style={{
                                            margin: "5px 15px 5px 15px",
                                            padding: 8,
                                            backgroundColor: darkTagColorForImpactLevel(tag.impactLevel),
                                            color: 'white',
                                            fontWeight: 'bold',
                                            maxWidth: 250
                                        }}
                                        label={tag.text}
                                        onClick={() => impactTagSelected(tag)}
                                    />
                                </div>
                            ))}
                        </div>
                    </React.Fragment>}
                </div>
            </Paper>);
    }

    let selectedAlertInfo: JSX.Element | undefined = undefined;
    if (selectedAlert) {
        // TODO: should we respect the filter the user has put on when determining this list?
        // maybe add a "X assets affected not being show because of filter" or equivalent
        const assetsAffectedByAlert: (LocationData | VehicleTrackingData)[] = [];
        let filteredAssetsAffectedByAlertCount = 0;
        userData.cities.forEach(city => {
            getAssetImpactTags(city, selectedTimeframeIndex).forEach(tag => {
                if (tag.id === selectedAlert.id) {
                    if (filteredCities.find(fc => fc.id === city.id)) {
                        assetsAffectedByAlert.push(city);
                    } else {
                        filteredAssetsAffectedByAlertCount += 1;
                    }
                }
            });
        });
        userData.vehicles.forEach(vehicle => {
            getAssetImpactTags(vehicle, selectedTimeframeIndex).forEach(tag => {
                if (tag.id === selectedAlert.id) {
                    if (filteredVehicles.find(fv => fv.id === vehicle.id)) {
                        assetsAffectedByAlert.push(vehicle);
                    } else {
                        filteredAssetsAffectedByAlertCount += 1;
                    }
                }
            });
        });

        assetsAffectedByAlert.sort((a, b) => getAssetImpactLevel(b, selectedTimeframeIndex) - getAssetImpactLevel(a, selectedTimeframeIndex));

        selectedAlertInfo = (
            <Paper className="selected-asset-info" elevation={4} style={{ backgroundColor: cardBackgroundColor, display: 'flex', flexDirection: 'column' }}>
                {alerts.length === 1 ? <Typography style={{ margin: "10px 0px 0px 15px" }} variant={'subtitle1'} fontWeight={"bold"} color={'textPrimary'}> {selectedAlert?.details.name}</Typography> :
                    <Select
                        value={selectedAlert.id}
                        variant={'outlined'}
                        style={{ margin: "5px", width: 'calc(100% - 10px)', height: 40 }}
                        onChange={(event) => setSelectedAlert(alerts.find(alert => alert.id === event.target.value))}
                    >
                        {alerts.map((alert: AlertData) => (
                            <MenuItem value={alert.id}>{alert.details.name}</MenuItem>
                        ))}
                    </Select>}
                <div style={{ overflow: 'auto', height: '100%' }}>
                    {<React.Fragment>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <Typography style={{ margin: "5px 15px" }} fontWeight={"bold"} variant={'body2'} color={'textPrimary'}>Assets at Risk ({assetsAffectedByAlert.length})</Typography>
                            <Button color={'primary'} size={'small'} disabled={assetsAffectedByAlert.length === 0} startIcon={<ExportIcon />} onClick={() => exportCSVFile(
                                assetsAffectedByAlert.map(asset => {
                                    return { asset: getAssetName(asset), latitude: asset.latitude, longitude: asset.longitude };
                                }), `assets_at_risk`)}>
                                Export
                            </Button>
                        </div>
                        {filteredAssetsAffectedByAlertCount > 0 && <Typography style={{ margin: "5px 15px", opacity: 0.5 }} variant={'caption'}>{filteredAssetsAffectedByAlertCount} affected assets filtered out</Typography>}
                        <div>
                            {(assetsAffectedByAlert.length > 0 || filteredAssetsAffectedByAlertCount > 0) ? assetsAffectedByAlert.map(asset => (
                                <div>
                                    <Chip
                                        style={{
                                            margin: "5px 15px 5px 15px",
                                            padding: 8,
                                            backgroundColor: darkTagColorForImpactLevel(getAssetImpactLevel(asset, selectedTimeframeIndex)),
                                            color: 'white',
                                            fontWeight: 'bold',
                                            maxWidth: 250
                                        }}
                                        label={getAssetName(asset)}
                                        onClick={() => {
                                            setSelectedAlert(undefined);
                                            if ((asset as any).assetType === 'vehicle') {
                                                onVehicleSelected(asset as VehicleTrackingData);
                                            } else {
                                                onCitySelected(asset as LocationData);
                                            }
                                        }}
                                    />
                                </div>
                            )) : <Typography style={{ margin: "5px 15px", opacity: 0.5 }} variant={'caption'}>No Assets Affected</Typography>}
                        </div>
                    </React.Fragment>}
                </div>
            </Paper>);
    }

    let selectedEventInfo: JSX.Element | undefined = undefined;
    // as cast fixes type casting for assignment in a forEach
    let selectedCyclonePolygon: CycloneData | undefined = undefined as CycloneData | undefined;
    if (selectedEvent && !showMapCalloutForSelectedEvent) {
        let selectedEventName: string | undefined = undefined;
        if (selectedCyclone) selectedEventName = 'Cyclone';
        else if (selectedPowerOutage) selectedEventName = 'Power Outage';
        else if (selectedStorm) selectedEventName = selectedStorm.stormType;
        else if (selectedWildfire) selectedEventName = 'Wildfire';
        if (selectedEvent && !showMapCalloutForSelectedEvent) {
            const eventAssetsMap = new Map<string, LocationData | VehicleTrackingData>();
            const eventPolygons: (GeoJSON.Polygon | GeoJSON.MultiPolygon)[] = [];
            let filteredAssetsAffectedByEventCount = 0;
            if (selectedCyclone) {
                cyclones?.forEach(cyclone => {
                    if (selectedCyclone.cycloneId !== cyclone.cycloneId) {
                        return;
                    }
                    if (cyclone.geoJson.geometry.type === 'Polygon') {
                        selectedCyclonePolygon = cyclone;
                        eventPolygons.push(turf.geometry('Polygon', cyclone.geoJson.geometry.coordinates) as GeoJSON.Polygon);
                    }
                    if (cyclone.geoJson.geometry.type === 'MultiPolygon') {
                        selectedCyclonePolygon = cyclone;
                        eventPolygons.push(turf.geometry('MultiPolygon', cyclone.geoJson.geometry.coordinates) as GeoJSON.MultiPolygon);
                    }
                });
            } else {
                if (selectedEvent.geoJson.geometry.type === 'Polygon') {
                    eventPolygons.push(turf.geometry('Polygon', selectedEvent.geoJson.geometry.coordinates) as GeoJSON.Polygon);
                }
                if (selectedEvent.geoJson.geometry.type === 'MultiPolygon') {
                    eventPolygons.push(turf.geometry('MultiPolygon', selectedEvent.geoJson.geometry.coordinates) as GeoJSON.MultiPolygon);
                }
            }
            eventPolygons.forEach(eventPoly => {
                userData.cities.forEach(city => {
                    if (city.id) {
                        const cityPoint = turf.point([city.longitude, city.latitude]);
                        if (turf.booleanPointInPolygon(cityPoint, eventPoly!)) {
                            if (filteredCities.find(fc => fc.id === city.id)) {
                                eventAssetsMap.set(String(city.id), city);
                            } else {
                                filteredAssetsAffectedByEventCount += 1;
                            }
                        }
                    }
                });
                userData.vehicles.forEach(vehicle => {
                    if (vehicle.latitude !== undefined && vehicle.longitude !== undefined) {
                        const vehiclePoint = turf.point([vehicle.longitude, vehicle.latitude]);
                        if (turf.booleanPointInPolygon(vehiclePoint, eventPoly!)) {
                            if (filteredVehicles.find(fv => fv.id === vehicle.id)) {
                                eventAssetsMap.set(vehicle.id, vehicle);
                            } else {
                                filteredAssetsAffectedByEventCount += 1;
                            }
                        }
                    }
                });
            });
            const assetsAffectedByEvent = Array.from(eventAssetsMap.values());
            assetsAffectedByEvent.sort((a, b) => getAssetImpactLevel(b, selectedTimeframeIndex) - getAssetImpactLevel(a, selectedTimeframeIndex));
            let assetAtRiskText = 'Assets at Risk';
            if (selectedCyclonePolygon?.feature === 'storm_cone_of_uncertainty') {
                assetAtRiskText = 'Assets in Cone of Uncertainty';
            } else if (selectedCyclonePolygon?.feature === 'storm_danger_swath') {
                assetAtRiskText = 'Assets in Danger Swath';
            }
            selectedEventInfo = (
                <Paper className="selected-asset-info" elevation={4} style={{ backgroundColor: cardBackgroundColor, display: 'flex', flexDirection: 'column' }}>
                    <Typography style={{ margin: "10px 0px 0px 15px" }} variant={'subtitle1'} fontWeight={"bold"} color={'textPrimary'}>{(selectedEvent as any | undefined)?.name ?? selectedEventName}</Typography>
                    <div style={{ overflow: 'auto', height: '100%' }}>
                        {<React.Fragment>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <Typography style={{ margin: "5px 15px" }} fontWeight={"bold"} variant={'body2'} color={'textPrimary'}>{assetAtRiskText} ({assetsAffectedByEvent.length})</Typography>
                                <Button
                                    color={'primary'}
                                    size={'small'}
                                    style={{ marginRight: '5px', 'minWidth': '85px' }}
                                    disabled={assetsAffectedByEvent.length === 0}
                                    startIcon={<ExportIcon />}
                                    onClick={() => {
                                        exportCSVFile(
                                            assetsAffectedByEvent.map(asset => {
                                                return { asset: getAssetName(asset), latitude: asset.latitude, longitude: asset.longitude };
                                            }),
                                            'assets_at_risk'
                                        );
                                    }
                                    }>
                                    Export
                                </Button>
                            </div>
                            {filteredAssetsAffectedByEventCount > 0 && <Typography style={{ margin: "5px 15px", opacity: 0.5 }} variant={'caption'}>{filteredAssetsAffectedByEventCount} affected assets filtered out</Typography>}
                            <div>
                                {(assetsAffectedByEvent.length > 0 || filteredAssetsAffectedByEventCount > 0) ? assetsAffectedByEvent.map(asset => (
                                    <div>
                                        <Chip
                                            style={{
                                                margin: "5px 15px 5px 15px",
                                                padding: 8,
                                                backgroundColor: darkTagColorForImpactLevel(getAssetImpactLevel(asset, selectedTimeframeIndex)),
                                                color: 'white',
                                                fontWeight: 'bold',
                                                maxWidth: 250
                                            }}
                                            label={getAssetName(asset)}
                                            onClick={() => {
                                                setSelectedEvent(undefined);
                                                if ((asset as any).assetType === 'vehicle') {
                                                    onVehicleSelected(asset as VehicleTrackingData);
                                                } else {
                                                    onCitySelected(asset as LocationData);
                                                }
                                            }}
                                        />
                                    </div>
                                )) : <Typography style={{ margin: "5px 15px", opacity: 0.5 }} variant={'caption'}>No Assets Affected</Typography>}
                            </div>
                        </React.Fragment>}
                    </div>
                </Paper>);
        }
    }

    let routeTableContainer: JSX.Element | undefined = undefined;
    // let selectedRouteInfo: JSX.Element | undefined = undefined;
    if (selectedAssetGroup === 'route' && (!selectedEvent || showMapCalloutForSelectedEvent) && !selectedAlert) {
        let currentRoute: RouteData | undefined = selectedRoute;
        if (currentRoute === undefined || filteredRoutes.find(route => route.id === currentRoute!.id) === undefined) {
            if (filteredRoutes?.length > 0) {
                // currentRoute = filteredRoutes.sort((a: RouteData, b: RouteData) => {
                //     if (a === undefined || a!.latestRouteResults === undefined) return 1;
                //     if (b === undefined || b!.latestRouteResults === undefined) return -1;
                //     return calculateImpactScore(b.latestRouteResults[0]) - calculateImpactScore(a.latestRouteResults[0]);
                // })[0];
                // setSelectedRoute(currentRoute);

                // TODO this causes a force re-render
                // For now, disabling the auto-selection of the first shipment.

                // setForceBounds(getBoundsForRoute(currentRoute));

                // const matchingVehicle = findMatchingVehicleForRoute(currentRoute, userData.savedRoutes, userData.vehicles);
                // onVehicleSelected(matchingVehicle);
            }
        }
        routeTableContainer = (
            <Paper className={"RoutesTableComponent"} elevation={4} style={{ backgroundColor: cardBackgroundColor }}>
                {focusedRoute && <Chip
                    style={{
                        margin: "5px 15px 5px 15px",
                        padding: 8,
                        fontWeight: 'bold',
                        width: 200,
                        boxSizing: 'border-box',
                        position: 'absolute',
                        left: 'calc(50% - 130px)',
                        zIndex: 1000,
                    }}

                    color={"primary"}

                    label={<div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center'
                    }}>
                        <div style={{ width: 8 }} />
                        <Typography variant={'body1'} style={{ fontWeight: 'bold' }}>Viewing 1 route</Typography>
                        <div style={{ width: 8 }} />
                        <CancelRounded />
                    </div>}
                    onClick={() => setFocusedRoute(undefined)}
                />}

                <TextField
                    placeholder={"Search"}
                    variant={'standard'}
                    style={{ position: 'absolute', right: 20, width: 250, zIndex: 1000 }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <IconButton size="small" onClick={() => setSearchQuery('')}>
                                <ClearOutlined style={{ visibility: searchQuery ? 'visible' : 'hidden' }} />
                            </IconButton>
                        )
                    }}
                    value={searchQuery}
                    onChange={(event) => setSearchQuery(event.target.value as string)}
                />
                <ImpactedRoutesTable
                    token={userData.token}
                    routes={focusedRoute ? [focusedRoute] : filteredRoutes}
                    routesMetadata={userData.savedRoutesMetadata}
                    vehicles={userData.vehicles}
                    vehiclesMetadata={userData.vehiclesMetadata}
                    refreshingRouteIds={userData.refreshingRouteIds}
                    onRouteRefreshRequested={(route) => onRouteRefreshRequested(route)}
                    selectedRoute={currentRoute}
                    onRouteSelected={(route) => {
                        if (route.id === selectedRoute?.id && route.selectedRouteOption === selectedRoute?.selectedRouteOption) {
                            // this gets called spuriously when you click a button in the row, so if we dont return here, 
                            // it breaks zooming to the selected vehicle when you hit the vehicle button
                            console.log("ignoring click on same row in impacted routes table");
                            return;
                        }

                        // select and zoom to route
                        setSelectedRoute(route);
                        setForceBounds(getBoundsForRoute(route));

                        // select paired vehicle is vehicle should be on route (current time is after departure time)
                        if (Config.getBoolean(Config.Key.ShowRouteVehiclePairs)) {
                            const vehicle = findMatchingVehicleForRoute(route, userData.savedRoutes, userData.vehicles);
                            onVehicleSelected(vehicle);
                        }
                    }}
                    pageSize={5}
                    // not used for nowcast?
                    selectedView={undefined}
                    onVehicleSelected={(selectedVehicle) => {
                        onVehicleSelected(selectedVehicle);
                        setForceCenter({ lat: selectedVehicle.latitude!, lng: selectedVehicle.longitude! });
                        setForceZoom(14);
                        setSelectedAssetGroup('vehicle');
                    }}
                    searchQuery={searchQuery}
                    blurbs={blurbs}
                />
            </Paper >
        );

        // not showing selected route component right now
        // if (currentRoute) {
        //     let impactLevel = ImpactLevel.Unknown;
        //     let slowdown = 0;
        //     let maxRoadIndex = 0;
        //     if (currentRoute.latestRouteResults) {
        //         impactLevel = findImpactLevel(currentRoute.latestRouteResults[0]);
        //         slowdown = currentRoute.latestRouteResults[0].slowdownFraction;
        //         maxRoadIndex = currentRoute.latestRouteResults[0].maxRoadIndex;
        //     }
        //     selectedRouteInfo = (
        //         <Paper className="selected-asset-info" elevation={4} style={{ backgroundColor: cardBackgroundColor, width: '300px', display: 'flex', flexDirection: 'column', borderRadius: '11px 11px 0px' }}>
        //             <Typography style={{ margin: "10px 0px 0px 15px" }} variant={'subtitle1'} fontWeight={"bold"} color={'textPrimary'}>{getAssetName(currentRoute)}</Typography>
        //             <Typography style={{ margin: "0px 0px 5px 15px", opacity: 0.5 }} variant={'caption'} >{getRouteDepartureTimeLabel(currentRoute)}</Typography>
        //             <div style={{ overflow: 'auto', height: '100%' }}>
        //                 <Typography style={{ margin: "5px 15px" }} variant={'body2'} fontWeight={"bold"} color={'textPrimary'}>Max impact for route</Typography>
        //                 <div style={{ margin: "5px 15px 10px", display: 'flex', flexDirection: 'row', alignContent: 'center' }}>
        //                     <Chip
        //                         style={{
        //                             padding: 8,
        //                             // subhourlyImpactSummary is missing if you click on the map on impact tab then switch to nowcast and click on a saved location
        //                             backgroundColor: darkTagColorForImpactLevel(impactLevel),
        //                             color: 'white',
        //                             fontWeight: 'bold',
        //                         }}
        //                         label={wordForImpactLevel(impactLevel)}
        //                     />
        //                 </div>
        //                 <Typography style={{ margin: "5px 15px" }} variant={'body2'} fontWeight={"bold"} color={'textPrimary'}>Specific Threats</Typography>
        //                 <div style={{ margin: "5px 15px 10px" }}>
        //                     <div>
        //                         <Tooltip title={blurbs?.road ? blurbs?.road[Math.floor(maxRoadIndex)].blurb : 'Unknown max road index'} placement="right">
        //                             <Chip
        //                                 style={{
        //                                     margin: "5px 15px 5px 15px",
        //                                     padding: 8,
        //                                     backgroundColor: blurbs?.road ? darkenToContrast(blurbs.road[Math.floor(maxRoadIndex)].color) : 'black',
        //                                     color: 'white',
        //                                     fontWeight: 'bold',
        //                                     maxWidth: 250
        //                                 }}
        //                                 label={`Max Road Index: ${maxRoadIndex}`}
        //                                 onClick={() => { }}
        //                             />
        //                         </Tooltip>
        //                         <Chip
        //                             style={{
        //                                 margin: "5px 15px 5px 15px",
        //                                 padding: 8,
        //                                 backgroundColor: darkTagColorForSlowdown(slowdown),
        //                                 color: 'white',
        //                                 fontWeight: 'bold',
        //                                 maxWidth: 250
        //                             }}
        //                             label={`Weather Related Slowdown: ${(slowdown * 100).toFixed(0)}%`}
        //                             onClick={() => { }}
        //                         />
        //                     </div>
        //                 </div>
        //             </div >
        //         </Paper >);
        // }
    }

    // this assumes these containers will only be defiend when they shouwl be visible
    let impactDetailView: JSX.Element | undefined = undefined;
    if (routeTableContainer || selectedAlertContainer || selectedEventContainer || timeSeriesGraphContainer) {
        impactDetailView = (
            <div style={{ display: 'flex', width: '100%', height: '100%', alignItems: 'flex-end' }}>
                <div style={{ height: '100%' }}>
                    {/* {selectedRouteInfo} */}
                    {selectedAlertInfo}
                    {selectedEventInfo}
                    {selectedLocationInfo}
                </div>

                <div style={{ width: '100%', overflow: 'auto' }}>
                    {routeTableContainer}
                    {selectedAlertContainer}
                    {selectedEventContainer}
                    {timeSeriesGraphContainer}
                </div>
            </div>
        );
    }

    let selectedEventLatitude: number | undefined = undefined;
    let selectedEventLongitude: number | undefined = undefined;
    if (selectedEvent && showMapCalloutForSelectedEvent) {
        const point = selectedEvent.clickedPoint ?? getPointForEvent(selectedEvent);
        selectedEventLatitude = point.latitude;
        selectedEventLongitude = point.longitude;
    }

    const matchingRoute = (selectedVehicle && selectedAssetGroup === 'vehicle') ? findMatchingRouteForVehicle(selectedVehicle, userData.savedRoutes) : undefined;
    const selectedVehicleElement: JSX.Element | undefined = (selectedVehicle && selectedAssetGroup === 'vehicle') ? (
        <VehicleTimelineView
            userData={userData}
            selectedVehicle={selectedVehicle}
            matchingRoute={matchingRoute}
            selectedChartView={selectedChartView}
            timeframe={timeframes[selectedTimeframeIndex]}

            onCloseClicked={() => onVehicleSelected(undefined)}
            setSelectedChartView={(view) => setSelectedChartView(view)}
            setAlerts={(alerts) => setAlerts(alerts)}
            setSelectedAlert={(alert) => setSelectedAlert(alert)}
            onVehicleMessageRequested={(vehicle, message, urgent) => onVehicleMessageRequested(vehicle, message, urgent)}
            onCenterPressed={(latitude, longitude) => {
                setForceCenter({ lat: latitude, lng: longitude });
                setForceZoom(14);
            }}

            onRoutePressed={(vehicle) => {
                setSelectedAssetGroup('route');
                const route = findMatchingRouteForVehicle(vehicle, userData.savedRoutes);

                setSelectedRoute(route);
                setFocusedRoute(route);
                if (route) {
                    setForceBounds(getBoundsForRoute(route));
                }
            }}

        // height={235}
        />
    ) : undefined;

    let metadataComponent: JSX.Element | undefined = undefined;
    if (selectedCity) {
        let riskRunTime: string | undefined = undefined;
        let graphRunTime: string | undefined = undefined;
        if (selectedTimeframeIndex === 0) {
            riskRunTime = moment(selectedCity.subhourlyImpactSummary?.sourceRunTime).utc().format();
            if (selectedChartView === 'weather') {
                graphRunTime = weatherData?.subhourly?.at(0)?.time.toISOString();
            } else if (selectedChartView === 'rating') {
                graphRunTime = subhourlyRatingsData?.disruption?.at(0)?.time.toISOString();
            }
        } else {
            riskRunTime = moment(selectedCity.extendedImpactSummary?.sourceRunTime).utc().format();
            if (selectedChartView === 'weather') {
                graphRunTime = weatherData?.hourly?.at(0)?.time.toISOString();
            } else if (selectedChartView === 'rating') {
                graphRunTime = ratingsData?.disruption?.at(0)?.time.toISOString();
            }
        }
        metadataComponent = (
            <div style={{
                position: 'absolute',
                left: '50%',
                transform: 'translateX(-50%)',
                bottom: '0px',
                background: 'white',
                opacity: 0.75,
                color: 'black',
                fontSize: '10px',
                paddingLeft: '2px',
                paddingRight: '2px',
            }}>
                {`${selectedCity.latitude.toFixed(5)}, ${selectedCity.longitude.toFixed(5)} | Risk Run Time: ${riskRunTime} | Graph Run Time: ${graphRunTime}`}
            </div>);
    }

    const currentStorms = React.useMemo(() => {
        if (displayedTime) {
            return (storms || []).filter(storm => displayedTime && storm.startTime <= displayedTime && storm.endTime >= displayedTime);
        } else {
            return (storms || []).filter(storm => storm.startTime <= timeframes[selectedTimeframeIndex].endTime.toDate() && storm.endTime >= timeframes[selectedTimeframeIndex].startTime.toDate());
        }
    }, [storms, displayedTime, timeframes, selectedTimeframeIndex]);

    // show the selected route either when we are on the routes view or on the vehicle view with a selected vehicle & corresponding route
    const shouldShowRoute = selectedAssetGroup === 'route' || (selectedAssetGroup === 'vehicle' && selectedVehicle !== undefined && selectedRoute !== undefined);

    const bottomContainer: JSX.Element = (
        <div className={"NowcastAssetGraphsContainer"}>
            {mapControls}
            {impactDetailView}
            {selectedVehicleElement}
        </div>
    );

    return (
        <div className={'Dashboard'} style={{ position: 'relative', height: '100%' }}>
            <RouteAndMarkerMap
                {...defaultRouteAndMarkerMapProps}

                padding={{
                    bottom: 300, // shipments table can get bulky
                    top: 100, // account for legends/timeframe selector, but not too much
                    right: 200 // account for side panel being open (probably common)

                    // TODO: maybe make this dynamic based on what is open..?
                }}

                portalToken={userData.portalToken}
                // initially show the whole conus
                initialCenter={{ lat: 39.82, lng: -98.57 }}
                forceCenter={forceCenter}
                forceZoom={forceZoom}
                onForceZoomCompleted={() => setForceZoom(undefined)}
                forceBounds={forceBounds}
                selectedCity={selectedCity}

                isCyclonesVisible={isCyclonesVisible}
                isEarthquakesVisible={isEarthquakesVisible}
                isLightningVisible={isLightningVisible}
                isPowerOutagesVisible={isPowerOutagesVisible}
                isStormsVisible={isStormsVisible}
                isStormReportsVisible={isStormReportsVisible}
                isVolcanoesVisible={isVolcanoesVisible}
                isFiresVisible={isFiresVisible}
                isWildfiresVisible={isWildfiresVisible}

                isRoadStatusVisible={userData.show511InPortal && isRoadStatusVisible}
                isRoadWorkVisible={userData.show511InPortal && isRoadWorkVisible}
                isRoadClosuresVisible={userData.show511InPortal && isRoadClosuresVisible}
                isSpecialEventsVisible={userData.show511InPortal && isSpecialEventsVisible}
                showTrafficFlow={isTrafficCongestionVisible}
                showTrafficCongestion={userData.show511InPortal && isTrafficCongestionVisible}
                showLegacyHereTrafficIncidents={userData.show511InPortal && isTrafficIncidentsVisible}
                showTrafficIncidents={userData.show511InPortal && isTrafficIncidentsVisible}
                isTruckWarningsVisible={userData.show511InPortal && isTruckWarningsVisible}
                isWeatherStationsVisible={userData.show511InPortal && isWeatherStationsVisible}

                cyclones={cyclones}
                showModelForecastTracksForCycloneId={showModelForecastTracksForCycloneId}
                earthquakes={earthquakes}
                lightning={lightning}
                powerOutages={powerOutages}
                storms={currentStorms}
                stormReports={stormReports}
                volcanoes={volcanoes}
                fires={fires}
                wildfires={wildfires}

                roadStatus={roadStatus}
                roadWork={roadWork}
                roadClosures={roadClosures}
                specialEvents={specialEvents}
                trafficCongestion={trafficCongestion}
                trafficIncidents={trafficIncidents}
                truckWarnings={truckWarnings}
                weatherStations={weatherStations}

                onCycloneSelected={(cyclone) => onCycloneSelected(cyclone)}
                onEarthquakeSelected={(earthquake) => onEarthquakeSelected(earthquake)}
                onLightningSelected={(lightning) => onLightningSelected(lightning)}
                onPowerOutageSelected={(powerOutage) => onPowerOutageSelected(powerOutage)}
                onStormSelected={(storm) => onStormSelected(storm)}
                onStormReportSelected={(stormReport) => onStormReportSelected(stormReport)}
                onVolcanoSelected={(volcano) => onVolcanoSelected(volcano)}
                onFireSelected={(fire) => onFireSelected(fire)}
                onWildfireSelected={(wildfire) => onWildfireSelected(wildfire)}

                onRoadStatusSelected={(roadStatus) => onRoadStatusSelected(roadStatus)}
                onRoadWorkSelected={(roadWork) => onRoadWorkSelected(roadWork)}
                onRoadClosureSelected={(roadClosure) => onRoadClosureSelected(roadClosure)}
                onSpecialEventSelected={(specialEvent) => onSpecialEventSelected(specialEvent)}
                onTrafficCongestionSelected={(trafficCongestion) => onTrafficCongestionSelected(trafficCongestion)}
                onTrafficIncidentSelected={(trafficIncident: TrafficIncidentData) => onTrafficIncidentSelected(trafficIncident)}
                onTruckWarningSelected={(truckWarning) => onTruckWarningSelected(truckWarning)}
                onWeatherStationSelected={(weatherStation) => onWeatherStationSelected(weatherStation)}

                selectedCyclone={selectedCyclone}
                selectedEarthquake={selectedEarthquake}
                selectedLightning={selectedLightning}
                selectedStorm={selectedStorm}
                selectedVolcano={selectedVolcano}
                selectedFire={selectedFire}
                selectedWildfire={selectedWildfire}
                selectedPowerOutage={selectedPowerOutage}
                selectedStormReport={selectedStormReport}

                selectedRoadStatus={selectedRoadStatus}
                selectedRoadWork={selectedRoadWork}
                selectedRoadClosure={selectedRoadClosure}
                selectedSpecialEvent={selectedSpecialEvent}
                selectedTrafficCongestion={selectedTrafficCongestion}
                selectedTrafficIncident={selectedTrafficIncident}
                selectedTruckWarning={selectedTruckWarning}
                selectedWeatherStation={selectedWeatherStation}

                zoomLevel={zoomLevel}
                selectedView={'location'}
                selectedTimelineView={'impact'}
                timeframe={timeframes[selectedTimeframeIndex]}

                showEventMarkersAboveLabels={tilingLayer !== undefined}

                isGovernmentalAlertsVisible={isAlertsVisible}
                governmentalAlerts={governmentalAlerts.value}
                selectedAlert={selectedAlert}

                maskType={(tilingLayer === undefined || tilingLayer === 'radar') ? MaskType.Labels : MaskType.LabelsAndWater}

                tileOpacity={tileOpacity}
                desiredTileLayer={desiredTileset}
                onTileLayerLoaded={(visibleTileset) => {
                    console.log('[Nowcast] onTileLayerLoaded', visibleTileset);
                    setVisibleTileset(visibleTileset);

                    if (!paused && visibleTileset?.id === desiredTileset?.id) {
                        console.log('[Nowcast] onTileLayerLoaded, queuing a new tile load');
                        clearTimeout(timerRef.current);
                        timerRef.current = setTimeout(() => goToNextTimeOffset(), 1000);
                    }
                }}

                mapTypeControlOptions={{
                    ...defaultRouteAndMarkerMapProps.mapTypeControlOptions,
                    mapTypeIds: (isRoadStatusVisible || isTrafficCongestionVisible || isTrafficIncidentsVisible ||
                        isRoadWorkVisible || isRoadClosuresVisible || isTruckWarningsVisible) ? ["weather_optics"] : ["satellite", "weather_optics"],
                }}

                isLocationsVisible={selectedAssetGroup === 'location'}
                savedCities={filteredCities}
                citiesLoading={userData.citiesMetadata.loading}

                // if we have no selected asset types, then we can just hide it without
                // clearing out all of the markers in filteredVehicles, makes toggling vehicles
                // less laggy
                isVehiclesVisible={selectedAssetGroup === 'vehicle' || (selectedAssetGroup === 'route' && selectedVehicle !== undefined)}
                vehicles={selectedAssetGroup === 'vehicle' ? filteredVehicles : (selectedVehicle === undefined ? [] : [selectedVehicle])}
                selectedVehicle={selectedVehicle}

                origin={shouldShowRoute ? selectedRoute?.latestRouteResults?.at(0)?.origin || selectedRoute?.origin : undefined}
                destination={shouldShowRoute ? selectedRoute?.latestRouteResults?.at(0)?.destination || selectedRoute?.destination : undefined}
                waypoints={shouldShowRoute ? selectedRoute?.waypoints : undefined}
                selectedRouteOption={shouldShowRoute ? selectedRoute?.selectedRouteOption : undefined}
                slowdownPolylines={shouldShowRoute && selectedRoute && selectedRoute.id === polylineRouteId && polylineType === 'slowdown' ? slowdownPolylines : undefined}
                roadRiskPolylines={shouldShowRoute && selectedRoute && selectedRoute.id === polylineRouteId && polylineType === 'road-risk' ? roadRiskPolylines : undefined}
                polylineType={shouldShowRoute ? polylineType : undefined}

                onCitySelected={(city) => onUserSelectedCity(city)}
                onVehicleSelected={(vehicle) => {
                    const matchingRoute = findMatchingRouteForVehicle(vehicle, userData.savedRoutes);
                    setSelectedRoute(matchingRoute);
                    onVehicleSelected(vehicle);
                }}
                onRouteSelected={(route) => setSelectedRoute(route)}
                onMapClicked={(coord) => onMapClicked(coord)}
                onBoundsChanged={(bounds) => setMapBounds(bounds)}
                onCenterChanged={(_center) => { }} // TODO: do we need to use this for anything?
                onZoomLevelChanged={(zoomLevel) => setZoomLevel(zoomLevel)}
            >
                {selectedEvent && showMapCalloutForSelectedEvent &&
                    <EventCalloutView
                        lat={selectedEventLatitude!}
                        lng={selectedEventLongitude!}
                        portalTab={'nowcast'}

                        selectedEarthquake={selectedEarthquake}
                        selectedLightning={selectedLightning}
                        selectedStormReport={selectedStormReport}
                        selectedVolcano={selectedVolcano}
                        selectedFire={selectedFire}
                        selectedPowerOutage={selectedPowerOutage}
                        selectedDrivingCondition={selectedRoadStatus}
                        selectedRoadWork={selectedRoadWork}
                        selectedRoadClosure={selectedRoadClosure}
                        selectedSpecialEvent={selectedSpecialEvent}
                        selectedTrafficCongestion={selectedTrafficCongestion}
                        selectedTrafficIncident={selectedTrafficIncident}
                        selectedTruckWarning={selectedTruckWarning}
                        selectedWeatherStation={selectedWeatherStation}

                        isEarthquakesVisible={isEarthquakesVisible}
                        isLightningVisible={isLightningVisible}
                        isPowerOutagesVisible={isPowerOutagesVisible}
                        isStormReportsVisible={isStormReportsVisible}
                        isVolcanoesVisible={isVolcanoesVisible}
                        isFiresVisible={isFiresVisible}
                        isDrivingConditionsVisible={isRoadStatusVisible}
                        isRoadWorkVisible={isRoadWorkVisible}
                        isRoadClosuresVisible={isRoadClosuresVisible}
                        isTrafficCongestionVisible={isTrafficCongestionVisible}
                        isTrafficIncidentsVisible={isTrafficIncidentsVisible}
                        isTruckWarningsVisible={isTruckWarningsVisible}

                        onClearSelectedEvent={() => onClearSelectedEvent()}
                        onClearSelectedRoadCondition={() => onClearSelectedRoadCondition()}
                    />}
            </RouteAndMarkerMap>
            {topLeftContainer}
            {assetsLoadingSpinner}
            <NowcastMenu
                userData={userData}
                selectedTimeframeIndex={selectedTimeframeIndex}

                tilingLayer={tilingLayer === 'lightning_probability' ? undefined : tilingLayer}
                setTilingLayer={(layer) => setTilingLayer(layer)}
                isGraphExpanded={impactDetailView !== undefined}
                itemCounts={{
                    cyclones: cyclones?.reduce((acc: any, curr: CycloneData) => {
                        if (!acc.includes(curr.cycloneId))
                            acc.push(curr.cycloneId);
                        return acc;
                    }, []).length.toLocaleString() ?? undefined,
                    earthquakes: earthquakes?.reduce((acc: any, curr: EarthquakeData) => {
                        if (!acc.includes(curr.earthquakeId))
                            acc.push(curr.earthquakeId);
                        return acc;
                    }, []).length.toLocaleString() ?? undefined,
                    lightning: lightning?.length.toLocaleString() ?? undefined,
                    powerOutages: powerOutages?.length.toLocaleString() ?? undefined,
                    storms: storms?.length.toLocaleString() ? currentStorms?.length.toLocaleString() : undefined,
                    stormReports: stormReports?.length.toLocaleString() ?? undefined,
                    volcanoes: volcanoes?.length.toLocaleString() ?? undefined,
                    fires: fires?.length.toLocaleString() ?? undefined,
                    wildfires: wildfires?.length.toLocaleString() ?? undefined
                }}
                assetAlerts={Array.from(new Set(selectedAssetGroup === 'location' ? locationAlerts : selectedAssetGroup === 'vehicle' ? vehicleAlerts : routeAlerts)).sort()}

                isAlertsVisible={isAlertsVisible}
                isCyclonesVisible={isCyclonesVisible}
                isEarthquakesVisible={isEarthquakesVisible}
                isLightningVisible={isLightningVisible}
                isPowerOutagesVisible={isPowerOutagesVisible}
                isStormsVisible={isStormsVisible}
                isStormReportsVisible={isStormReportsVisible}
                isVolcanoesVisible={isVolcanoesVisible}
                isFiresVisible={isFiresVisible}
                isWildfiresVisible={isWildfiresVisible}

                onAlertsToggled={(toggled) => setIsAlertsVisible(toggled)}
                onCyclonesToggled={(toggled) => onCyclonesToggled(toggled)}
                onEarthquakesToggled={(toggled) => onEarthquakesToggled(toggled)}
                onLightningToggled={(toggled) => onLightningToggled(toggled)}
                onPowerOutagesToggled={(toggled) => onPowerOutagesToggled(toggled)}
                onStormsToggled={(toggled) => onStormsToggled(toggled)}
                onStormReportsToggled={(toggled) => onStormReportsToggled(toggled)}
                onVolcanoesToggled={(toggled) => onVolcanoesToggled(toggled)}
                onFiresToggled={(toggled) => onFiresToggled(toggled)}
                onWildfiresToggled={(toggled) => {
                    // sync wildfire map layer and wildfire event visibility
                    onWildfiresToggled(toggled);
                    toggled ? setTilingLayer('wildfire_spread') : setTilingLayer(undefined);
                }}

                trafficLayer={trafficLayer}
                onTrafficLayerSelected={(layer) => onTrafficLayerSelected(layer)}

                selectedAssetGroup={selectedAssetGroup as AssetGroupType}
                setSelectedAssetGroup={(assetGroup) => setSelectedAssetGroup(assetGroup)}

                locations={filteredCities.sort((a, b) => getAssetImpactLevel(b, selectedTimeframeIndex) - getAssetImpactLevel(a, selectedTimeframeIndex))}
                isLocationsLoading={userData.citiesMetadata.loading}
                locationAssetTypeOptions={locationAssetTypeOptions}
                locationSelectedAssetTypes={locationSelectedAssetTypes}
                locationCountsByAssetCategory={locationCountsByAssetCategory}
                onLocationSelectedTypesChanged={(assetTypes) => setLocationSelectedAssetTypes(assetTypes)}

                vehicles={filteredVehicles.sort((a, b) => getAssetImpactLevel(b, selectedTimeframeIndex) - getAssetImpactLevel(a, selectedTimeframeIndex))}
                isVehiclesLoading={userData.vehiclesMetadata.loading}
                vehicleAssetTypeOptions={vehicleAssetTypeOptions}
                vehicleSelectedAssetTypes={vehicleSelectedAssetTypes}
                vehicleCountsByAssetCategory={vehicleCountsByAssetCategory}
                onVehicleSelectedTypesChanged={(assetTypes) => setVehicleSelectedAssetTypes(assetTypes)}

                shipments={filteredRoutes.sort((a, b) => getAssetImpactLevel(b, selectedTimeframeIndex) - getAssetImpactLevel(a, selectedTimeframeIndex))}
                isShipmentsLoading={userData.savedRoutesMetadata ? userData.savedRoutesMetadata.loading : false}
                shipmentAssetTypeOptions={routeAssetTypeOptions}
                shipmentSelectedAssetTypes={routeSelectedAssetTypes}
                shipmentCountsByAssetCategory={routeCountsByAssetCategory}
                onShipmentSelectedTypesChanged={(assetTypes) => setRouteSelectedAssetTypes(assetTypes)}

                onAssetSelected={(asset) => {
                    setSelectedEvent(undefined);
                    if ((asset as any).assetType === 'vehicle') {
                        onVehicleSelected(asset as VehicleTrackingData);
                    } else if ((asset as any).assetType === 'location') {
                        onCitySelected(asset as LocationData);
                    } else {
                        setSelectedRoute(asset as RouteData);
                    }
                }}
                mapViewport={mapBounds !== undefined ? { neLatitude: mapBounds.ne.lat, neLongitude: mapBounds.ne.lng, swLatitude: mapBounds.sw.lat, swLongitude: mapBounds.sw.lng } : undefined}

                filters={filters}
                onFiltersChanged={(filters) => setFilters(filters)}
            />

            {bottomContainer}
            {metadataComponent}

            <PortalTour run={startPortalTour} portalTab="MAP" />
        </div >
    );
};